import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import store from '../../store/store';
import { popUpClose } from '../../actions/pop-ups';
import { resetPreview } from '../../actions/preview';


export default ({children, large}) => (
  <div className="pop-up">
    <div className={classnames({
      "pop-up__container": true,
      "pop-up__container--large": large
    })}>
      {children}
    </div>
  </div>
);
