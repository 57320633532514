import { FETCH_INDEXING_STATUS } from "../constants/constants";

const defaultState = {
  in_queue: false
};

export default function statsReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_INDEXING_STATUS:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
