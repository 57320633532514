import React from 'react';
import { connect } from 'react-redux';
import { CHAPTERS, PARTS } from '../../constants/endpoints';
import { ADD_CHAPTER, GET_ALL_PARTS, GET_ALL_CHAPTERS } from '../../constants/constants';
import DefaultAddWithSelect from './default-add-with-select';

function AddChapter({ dispatch, state }){
  const { asyncAction, bookSection } = state;
  const postData = (title, parent_id) => ({
    chapter: {
      title,
      number: bookSection.chapters.length++,
      part_id: parent_id
    }
  });

  const parts = bookSection.parts.map( (part) => {
    return {
      title: part.title,
      id: part._id.$oid
    };
  });

  const data = {
    sectionTitle: 'Chapter',
    endpoint: `${CHAPTERS}`,
    action: ADD_CHAPTER,
    postData,
    dispatch,
    asyncAction,
    childSections: parts,
    childSectionsEndPoint: PARTS,
    childSectionsAction: GET_ALL_PARTS,
    getAllCurrentAction: GET_ALL_CHAPTERS
  };

  return (
    <DefaultAddWithSelect
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(AddChapter);
