import { Component } from 'react'
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import classnames from 'classnames';
import { retrieveData } from '../actions/fetch-data';
import { selectorMatches } from '../utils/content';
import { SEARCH } from '../constants/endpoints';
import {
  GET_SEARCH_RESULTS,
  PER_PAGE,
  DEFAULT_SEARCH_PAGE
} from '../constants/constants';
import Header from '../components/headers/header';
import AddSections from '../components/add/add-sections';
import SearchHeader from '../components/search/search-header';
import Tag from '../components/tags/tag';
import Pagination from '../components/search/search-pagination';
import SearchBox from '../components/search/search';


class SearchPage extends Component {

  componentDidMount(){
    const { query } = this.props.location;
    const searchParams = queryString.stringify(query);
    const url = `${SEARCH}?${searchParams}`;
    this.props.retrieveData(url, GET_SEARCH_RESULTS);
  }

  renderSearchResults(){
    return this.props.state.searches.data.map((search, index) => {
      const editPopup = `EDIT_${search.type.toUpperCase()}_POPUP`;
      const deletePopup = `DELETE_${search.type.toUpperCase()}_POPUP`;

      return (
        <Tag
          key={index}
          data={search.item}
          sectionTitle={`${search.type}s`}
          editChildAction={editPopup}
          deleteChildAction={deletePopup}
        />
      )
    })
  }

  generateFilterList(){
    const items = ['all','checklists','topics','chapters', 'parts'];
    const { query } = this.props.location;
    const filter = query.filter || 'all';
    const list = items.map((name, index) => {
      let classname = classnames({
        selected: filter.toLowerCase() === name
      })
      return <li className={classname} key={index}>{name}</li>
    })

    return <ul onClick={this.switchFilters.bind(this)}>{list}</ul>
  }

  switchFilters(e){
    const lists = e.currentTarget.childNodes;
    [].forEach.call(lists, (item) => item.classList.remove('selected'))

    if(selectorMatches(e.target, 'li')){
      e.target.classList.toggle('selected');
      const {query, pathname:url} = this.props.location;
      const queryParams = queryString.stringify({
        q: query.q,
        page: DEFAULT_SEARCH_PAGE,
        per_page: PER_PAGE,
        filter: e.target.textContent
      });

      browserHistory.replace(`${url}?${queryParams}`)
    }
  }

  render(){
    const { query } = this.props.location;
    const { total_pages, total_count, total_showing } = this.props.state.searches;
    return (
      <div>
        <SearchHeader
          requestActive={this.props.state.asyncAction.requestActive}
          location={this.props.location}
          totalShowing={total_showing}
          totalCount={total_count}
          totalPages={total_pages}
        />
        <div className="content">
          <SearchBox />

          <div className="search-filter">
            {this.generateFilterList()}
          </div>

          <ul className="tag-grid tag-grid--no-bottm-margin search-items">
            {(this.props.state.asyncAction.requestActive)? null : this.renderSearchResults()}
          </ul>

          {
            (this.props.state.asyncAction.requestActive)?
            null :
            (
              <Pagination
                totalPages={total_pages}
                location={this.props.location}
              />
            )
          }
        </div>
      </div>
    )
  }
}

export default connect(
  nextState => ({state: nextState}),
  { retrieveData }
)(SearchPage)
