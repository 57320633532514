import React from "react";
import {connect} from "react-redux";
import { retrieveData, createData } from "../actions/fetch-data";
import Header from "../components/headers/header";

import { CONFIG } from '../constants/endpoints';
import {
  UPDATE_CONFIG,
  FETCH_CONFIG,
} from '../constants/constants';
import './content.scss';

class ContentPage extends React.Component {
  constructor(props) {
    super(props);
    this.videoChanged = this.videoChanged.bind(this);
    this.saveVideo = this.saveVideo.bind(this);
    this.state = {
      config: {
        video: false
      }
    };
  }

  componentDidMount(){
    this.props.retrieveData(CONFIG, FETCH_CONFIG);
  }

  componentWillReceiveProps(props) {
    this.setState({ config: props.state.config });
  }

  videoChanged(e) {
    this.setState({ config: { video: e.target.value } });
  }

  saveVideo(e) {
    //store video to somewhere
    const { video } = this.state.config;

    this.props.createData(CONFIG, UPDATE_CONFIG, {
      config: {
        video: video
      }
    });
  }

  render() {
    const { video } = this.state.config;

    return (
      <div>
        <Header title="Content" subtitle="Manage site-wide content" />
        <div className="content">
          <div>
            <h2>Default video</h2>
            <div className="video-columns pure-control-group">
              <div className="video-column">
                <label>Video code</label>
                <p>This video will be used as the default unless <br />one is added to a category, part, or checklist directly</p>
                <input
                  type="text"
                  onChange={this.videoChanged}
                  defaultValue={video ? video : ''}
                  placeholder="Default video"
                />

              {video ?
                <div>
                  <p>Ensure you are happy with the preview before saving</p>
                  <button className="pure-button pure-button-primary" onClick={this.saveVideo}>Save</button>
                </div>
                : null}
              </div>
              {video ? (
                <div className="video-column">
                  <label>Preview</label>
                  <iframe
                    style={{
                      width: 540,
                      height: 315,
                    }}
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${video}`}
                    title="Neurochecklists Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : <div className="video-column"></div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  nextState => ({ state: nextState }),
  { retrieveData, createData }
)(ContentPage)

