import {
  ASYNC_REQUEST,
  ASYNC_REQUEST_COMPLETE,
  ASYNC_REQUEST_ERROR,
  ASYNC_REQUEST_UPDATE_SUCCESS,
  ASYNC_REQUEST_ACTION_FAILED,
  ASYNC_REQUEST_CLEAR_STATUS
} from '../constants/constants';

const defaultState = {
  requestActive: false,
  updateSuccess: false,
  updateFailed: false,
  error: false,
  errMsg: ''
};

export default function retrieveDataReducer(state = defaultState, action){
  switch (action.type) {
    case ASYNC_REQUEST:
      return Object.assign({}, state, {
        requestActive: true,
        error: false,
        errMsg: ''
      });

    case ASYNC_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        requestActive: false,
        error: false,
        errMsg: ''
      });

    case ASYNC_REQUEST_ERROR:
      return Object.assign({}, state, {
        requestActive: false,
        error: true,
        errMsg: action.errMsg
      });

    case ASYNC_REQUEST_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        updateSuccess: true,
        updateFailed: false
      });

    case ASYNC_REQUEST_ACTION_FAILED:
      return Object.assign({}, state, {
        updateSuccess: false,
        updateFailed: true
      });

    case ASYNC_REQUEST_CLEAR_STATUS:
    return Object.assign({}, state, {...defaultState});

    default:
      return state;
  }
}
