export function postConfig(payload){
  return {
    method: 'POST',
    mode: 'cors',
	  redirect: 'manual',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
}

export function apiPostConfig(method = 'POST', credentials, payload){
  return {
    method,
    mode: 'cors',
	  redirect: 'manual',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...credentials
    },
    body: JSON.stringify(payload)
  };
}

export function apiPostFileConfig(method = 'POST', credentials, payload){
  return {
    method,
    mode: 'cors',
	  redirect: 'manual',
    headers: {
      'Accept': 'application/json',
      ...credentials
    },
    body: payload
  };
}

export const fetchData = (url, headers) =>{
  return fetch(url, {
      headers,
      mode: 'cors',
  	  redirect: 'manual',
    })
    .then((res) => {
      if(res.ok ){
        return res.json();
      } else {
        throw new Error(`Failed to load data at ${res.url}`);
      }
    });
};
