import React from 'react';

export default ({data})=> {
  var options = data.map((item, index) => {
    return (
      <option
        value={item.title}
        key={index}
      />

    );
  })
  return (
    <div>
      <input
        id="section_id"
        className="form-control"
        list="section_data"
        name="belongs_to"
        placeholder="Start typing.."
        required={true}
      />
      <datalist
        id="section_data"
        placeholder="Start typing..."
      >
        {options}
      </datalist>
    </div>
  )
}
