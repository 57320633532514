import {
  GET_STATS
} from '../constants/constants';

const defaultState = {
  total_users: 0,
  paid_users: 0,
  free_users: 0,
  checklists: 0,
  topics: 0,
  chapters: 0,
  parts: 0
};

export default function statsReducer(state=defaultState, action){
  switch (action.type) {
    case GET_STATS:
      return Object.assign({}, state, action.data)
    default:
      return state;
  }
}
