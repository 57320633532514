import React from 'react';
import { connect } from 'react-redux';
import {
  TOPICS,
  CHECKLISTS,
  CHECKLISTS_PREVIEW
} from '../../constants/endpoints';
import {
  UPDATE_CHECKLIST,
  PREVIEW_CHECKLIST
} from '../../constants/constants';
import DefaultEditWithPreview from './default-edit-with-preview';

function EditChecklist({
  dispatch,
  state,
  _id,
  topic_id,
  title,
  title_footnotes,
  sections,
  signposts
}){

  const { asyncAction } = state;
  const checklist_id = _id.$oid;
  const parent_topic_id = topic_id.$oid
  const onLoadPreviewData = {
    hasBeenPreviewed: state.checklist.hasBeenPreviewed,
    hasBeenSubmited: state.checklist.hasBeenSubmited,
    data: {
      _id,
      topic_id,
      title: {
        title: {
          text: title,
          "footnotes": title_footnotes || []
        }
      },
      sections,
      signposts
    }
  };

  const previewData = (Object.keys(state.checklist.data).length)? state.checklist : onLoadPreviewData;
  const putData = (file) => {
    const data = new FormData();
    data.append("checklist[file]", file, 'checklist.docx');
    data.append("checklist[topic_id]", parent_topic_id);
    data.append("checklist[id]", checklist_id);

    return data;
  }


  const data = {
    sectionTitle: 'Checklist',
    title,
    previewEndPoint: CHECKLISTS_PREVIEW,
    updateEndpoint: `${CHECKLISTS}/${checklist_id}`,
    previewAction: PREVIEW_CHECKLIST,
    updateAction: UPDATE_CHECKLIST,
    putData,
    dispatch,
    asyncAction,
    childSectionsEndPoint: TOPICS,
    previewData
  };

  return (
    <DefaultEditWithPreview
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(EditChecklist);
