import {
  GET_ALL_PARTS,
  GET_ALL_CHAPTERS,
  GET_ALL_TOPICS
} from '../constants/constants.js';

const defaultState = {
  parts: [],
  chapters: [],
  topics: []
};

export default function bookReducer(state=defaultState, action){
  switch(action.type){
    case GET_ALL_PARTS:
    return Object.assign({}, state, {
      parts: action.data
    });
    case GET_ALL_CHAPTERS:
    return Object.assign({}, state, {
      chapters: action.data
    });
    case GET_ALL_TOPICS:
      return Object.assign({}, state, {
        topics: action.data
      });
    default:
      return state
  }
}
