import React from 'react';
import {
  textWithFootNotes,
  generateLists
} from '../../utils/content';


export default ({title, list}) => (
  <div className="checklist-section">
    <header className="checklist-section--header">
      <div className="l-box">
        <h2>{textWithFootNotes(title)}</h2>
      </div>
    </header>
    <div className="l-box checklist-content">
      <ul>
        { generateLists(list) }
      </ul>
    </div>
  </div>
)
