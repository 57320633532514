import React from 'react';
import classnames from 'classnames';
import ErrorNotice from '../notice/standard-error';
import SuccessNotice from '../notice/standard-success';
import Autocomplete from '../custom-elements/autocomplete';
import { popUpClose } from '../../actions/pop-ups';
import { resetPreview } from '../../actions/preview';
import { createDataFile, retrieveData } from '../../actions/fetch-data';
import Checklist from '../checklist/checklist';
import {
  SERVER_FAILURE_ERROR,
  SERVER_SUCCESS_CREATE
} from '../../constants/messages';


export default class DefaultAddWithPreview extends React.Component {

  componentDidMount(){
    const {
      childSectionsEndPoint,
      childSectionsAction,
      dispatch,
    } = this.props;

    dispatch(
      retrieveData(
        childSectionsEndPoint,
        childSectionsAction
      )
    );
  }

  handleSubmit(e){
    e.preventDefault();
    const belongs_to = e.target.querySelector('input[name=belongs_to]').value.trim();
    const file = e.target.querySelector('input[name=document]').files[0];
    const {
      addAction,
      addEndpoint,
      postData,
      dispatch,
      childSections,
      previewData
    } = this.props;

    const belongs_to_id = childSections
      .filter( section => section.title === belongs_to)
      .map( section => section.id)[0]

    dispatch(
      createDataFile(
        addEndpoint,
        addAction,
        postData(file, belongs_to_id)
      )
    );
  }

  handlePreview(e){
    e.preventDefault();
    const file = this.refs.file.files[0];
    const {
      previewAction,
      previewEndPoint,
      postData,
      dispatch
    } = this.props;

    if(!file) return;

    dispatch(
      createDataFile(
        previewEndPoint,
        previewAction,
        postData(file)
      )
    );
  }

  render(){
    const {
      sectionTitle,
      dispatch,
      childSections,
      previewData,
      asyncAction
    } = this.props;

    const {
      requestActive,
      updateSuccess,
      updateFailed
    } = asyncAction;

    const loadingClasses = classnames({
      'loading--centered': true,
      hide: !requestActive
    });

    return(
      <div className="modal">
        <div className="modal-dialog modal-dialog--large">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                aria-hidden="true"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch( popUpClose() )
                  dispatch( resetPreview() )
                }}
              >&times;</button>
              <h4 className="modal-title">{`Upload ${sectionTitle}`}</h4>
            </div>
            <div className="modal-body modal-body--dark">
              <div className="pure-g">
                <div className="pure-u-1 pure-u-md-10-24">
                  <form className="pure-form pure-form-stacked" onSubmit={this.handleSubmit.bind(this)} encType="multipart/form-data" autoComplete="off">
                    <ErrorNotice msg={SERVER_FAILURE_ERROR} shouldDisplay={updateFailed} />
                    <SuccessNotice msg={SERVER_SUCCESS_CREATE} shouldDisplay={updateSuccess && previewData.hasBeenSubmited} />

                    <div className="pure-control-group">
                      <label htmlFor="document">Upload a checklist:</label>
                      <input ref="file" type="file" name="document" id="file-input" required/>
                    </div>

                    <div className="pure-control-group">
                      <label htmlFor="title">Belongs to topic:</label>
                      <Autocomplete data={childSections}/>
                    </div>

                    <div className="pure-controls clearfix">
                      <button
                        type="button"
                        onClick={this.handlePreview.bind(this)}
                        className="btn button-success pure-button fl"
                        disabled={previewData.hasBeenPreviewed}
                      ><i className="fa fa-eye"></i> Preview</button>

                      <button
                        type="submit"
                        className="btn pure-button pure-button-primary fr"
                        disabled={!previewData.hasBeenPreviewed || updateSuccess && previewData.hasBeenSubmited}
                      ><i className="fa fa-check"></i> Add
                      </button>

                      {(previewData.hasBeenPreviewed)? (
                        <div className="clearfix center-content">
                          <button
                            type="button"
                            className="btn button-warning pure-button"
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch( resetPreview() );
                            }}
                            disabled={updateSuccess && previewData.hasBeenSubmited}
                          >
                            <i className="fa fa-refresh"></i> Something's not right?
                          </button>
                        </div>
                      ):(null)}
                    </div>
                  </form>
                </div>
                <div className="pure-u-1 pure-u-md-14-24 preview-side">
                  <div className="l-box-2">
                    {
                      (Object.keys(previewData.data).length)
                        ? (<Checklist data={previewData.data} />)
                        : (<p style={{textAlign: 'center'}}>Click preview the see the checklist</p>)
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <img
                className={loadingClasses}
                src={require('../../../images/loading-c.gif')}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch( popUpClose() )
                  dispatch( resetPreview() )
                }}
                className="btn button-cancel pure-button"
              >Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
