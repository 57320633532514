import React from "react";
import Header from '../components/headers/header';
import { connect } from 'react-redux';
import { retrieveData } from '../actions/fetch-data';
import { topic_selected } from '../actions/topics';
import classnames from 'classnames';
import {
  CHAPTERS,
  TOPICS
} from '../constants/endpoints';
import {
  FETCH_TOPIC_DATA,
  FETCH_TOPIC_CHECKLIST_DATA,
  TOPIC_SELECTED,
  EDIT_TOPIC_POPUP,
  DELETE_TOPIC_POPUP,
  EDIT_CHECKLIST_POPUP,
  DELETE_CHECKLIST_POPUP
} from '../constants/constants';

import TagGrid from '../components/tags/tag-grid';
import ExpandingTagItem from '../components/tags/expanding-tag';
import AddSections from '../components/add/add-sections';
import SearchBox from '../components/search/search';

class ChapterPage extends React.Component {
  componentDidMount(){
    const page_id = this.props.params.id;
    const url = `${CHAPTERS}/${page_id}/topics`
    this.props.retrieveData(url, FETCH_TOPIC_DATA);
  }

  fetchChildrenChecklist(id, url, actionType){
    this.props.topic_selected(id);
    this.props.retrieveData(url, actionType, id);
  }

  render(){
    const { requestActive } = this.props.state.asyncAction;
    const { topics } = this.props.state.topics;
    const page_id = this.props.params.id;
    const chapter_title = page_id
                            .split('-')
                            .map( word => (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              )
                            )
                            .join(' ');

    let tags = topics.map(( topic, index ) => {
      return (
        <ExpandingTagItem
          key={index}
          data={topic}
          childTitle='Checklists'
          editAction={EDIT_TOPIC_POPUP}
          deleteAction={DELETE_TOPIC_POPUP}
          editChildAction={EDIT_CHECKLIST_POPUP}
          deleteChildAction={DELETE_CHECKLIST_POPUP}
          onSelect={
            this.fetchChildrenChecklist.bind(
              this,
              topic._id.$oid,
              `${TOPICS}/${topic._id.$oid}/checklists`,
              FETCH_TOPIC_CHECKLIST_DATA
            )
          }
          childUrl=''
        />)
    });

    return (
      <div>
        <Header title="Chapter" subtitle={chapter_title}/>
        <div className="content">
          <SearchBox />
          <div className="mobile-hide">
            <AddSections />
          </div>
          <h2>Topics</h2>
          <TagGrid>
            {tags}
          </TagGrid>
        </div>
      </div>
    );
  }
}

export default connect(
  nextState => ({ state: nextState }),
  { retrieveData, topic_selected }
)(ChapterPage)
