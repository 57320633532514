import React from 'react';
import TagItem from './tag'

export default ({data, url, title, editChildAction, deleteChildAction})=> {
  const tags = data.map(( item, index ) => {
    return (
      <TagItem
        data={item}
        url={url}
        sectionTitle={title}
        key={index}
        editChildAction={editChildAction}
        deleteChildAction={deleteChildAction}
      />
    )
  });

  return (
    <div className="tag-expander">
      <div className="tag-details">
        <ul>
          {tags}
        </ul>
      </div>
    </div>
  )
}
