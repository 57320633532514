function generateFootnotes(data){
  return data.map((footnote, i, array) => {
    let lastItemIndex = array.length - 1;
    let isLast = i === lastItemIndex;
    return <sup key={i}>{footnote}{isLast? '': ', '}</sup>
  })
}

function genereteSubList(list){
  if(!list) return;
  return list.map( (item, index) => {
    let title = textWithFootNotes(item.title);
    return (
      <ul key={index}>
        <li>{title}</li>
      </ul>
    )
  })
}

export function selectorMatches(el, selector){
	var p = Element.prototype;
	var f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function(s) {
		return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
	};
	return f.call(el, selector);
}

export function textWithFootNotes(titleObj){
  let title = titleObj.text;
  let footnotes = titleObj.footnotes;
  let parsed_footnotes = '';

  if(footnotes && footnotes.length){
    parsed_footnotes = generateFootnotes(footnotes);
  }

  return(
    <span className="text-content">{title} {parsed_footnotes}</span>
  )
}

export function generateLists(list) {
  return list.map( (item, index) => {
    let title = textWithFootNotes(item.title);
    let { sub_list } = item;
    let innerList = (sub_list) ? genereteSubList(sub_list): '';
    return(
      <li key={index}>
        {title}
        {innerList}
      </li>
    )
  })
}


export function listWithLinks(list){
  return list.map((link, i) =>{
    return(
      <li key={i}>
        <a href={link.url} target="_blank">{link.title}</a>
      </li>
    );
  })
}
