import React from 'react';
import { textWithFootNotes } from '../../utils/content';


export default ({title}) => (
  <header className="bg-white checklist-header">
    <div className="inner-container pure-g">
      <div className="pure-u-1">
        <div className="l-box">
          <h1>{textWithFootNotes(title)}</h1>
        </div>
      </div>
    </div>
  </header>
)
