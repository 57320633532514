import fetch from 'isomorphic-fetch';
import { postConfig } from '../utils/data';
import { allowed_users } from '../constants/whitelist';
import {
  KEY,
  ATTEMPT_LOGIN,
  REQUEST_LOGIN_USER_ERROR,
  REQUEST_LOGIN_USER_INVALID,
  REQUEST_LOGIN_USER_SUCCESS
} from '../constants/constants';

import { storeSessionUser } from '../persistance/storage';

import { LOGIN } from '../constants/endpoints';

export function attemptLogin({email, password, dispatch}, redirect){

  if(allowed_users.indexOf(email) < 0) {
    return dispatch({
      type: REQUEST_LOGIN_USER_INVALID,
      errMsg: {error : 'Whoops something went wrong' }
    });
  }


  performLogin({email, password, dispatch}, redirect);

  return {
    type: ATTEMPT_LOGIN,
    email,
    password,
    performingRequest: true
  }
}

function performLogin({email, password, dispatch}, redirect){
  return fetch(LOGIN, postConfig(
    {
      user: {
        email,
        password
      }
    }
  ))
  .then((res)=> {
    if (res.ok) {
      return res.json();
    }

    throw {error : 'Invalid email or password.'}
  }).then((res) => {
    if (res.error) {
      return dispatch({
        type: REQUEST_LOGIN_USER_ERROR,
        errMsg: res
      });
    }
    storeSessionUser(KEY, res);
    dispatch({
      type: REQUEST_LOGIN_USER_SUCCESS,
      data: res
    });
    if(redirect) redirect();
  })
  .catch((error) => {
    dispatch({
      type: REQUEST_LOGIN_USER_ERROR,
      errMsg: error || {error : 'Whoops something when wrong' }
    });
  });
}
