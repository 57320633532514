import React from "react";
import classnames from 'classnames';
import { connect } from 'react-redux';


function Header({state, title, subtitle}) {
  const { requestActive } = state.asyncAction;

  const loadingClasses = classnames({
    'page-data-loading': true,
    'page-data-loading--finished': !requestActive
  });

  return (
    <header className="header">
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <img
        className={loadingClasses}
        src={require('../../../images/loading-c.gif')}
      />
    </header>
  )

}

export default connect(nextState => ({ state: nextState }))(Header)
