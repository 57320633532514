export const KEY = '7qNlq4HrMDEfMSm';

export const PER_PAGE = 15;
export const USER_PER_PAGE = 25;
export const DEFAULT_SEARCH_PAGE = 1;

export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const REQUEST_LOGIN_USER_ERROR = 'REQUEST_LOGIN_USER_ERROR';
export const REQUEST_LOGIN_USER_INVALID = 'REQUEST_LOGIN_USER_INVALID';
export const REQUEST_LOGIN_USER_SUCCESS = 'REQUEST_LOGIN_USER_SUCCESS';

export const ASYNC_REQUEST = 'ASYNC_REQUEST';
export const ASYNC_REQUEST_COMPLETE = 'ASYNC_REQUEST_COMPLETE';
export const ASYNC_REQUEST_ERROR = 'ASYNC_REQUEST_ERROR';
export const ASYNC_REQUEST_UPDATE_SUCCESS = 'ASYNC_REQUEST_UPDATE_SUCCESS';
export const ASYNC_REQUEST_ACTION_FAILED = 'ASYNC_REQUEST_ACTION_FAILED';
export const ASYNC_REQUEST_CLEAR_STATUS = 'ASYNC_REQUEST_CLEAR_STATUS';

export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const DELETE_SEARCH_RESULT = 'DELETE_SEARCH_RESULT';
export const EDIT_SEARCH_RESULT = 'EDIT_SEARCH_RESULT';

export const GET_STATS = 'GET_STATS';

export const GET_USERS_DATA = 'GET_USERS_DATA';
export const VIEW_USER_POPUP = 'VIEW_USER_POPUP';
export const DELETE_USER_POPUP = 'DELETE_USER_POPUP';
export const DELETE_USER = 'DELETE_USER';
export const SEND_CONFIRMATION_REMINDER = 'SEND_CONFIRMATION_REMINDER';

export const GET_ALL_PARTS = 'GET_ALL_PARTS';
export const GET_ALL_CHAPTERS = 'GET_ALL_CHAPTERS';
export const GET_ALL_TOPICS = 'GET_ALL_TOPICS';

export const FETCH_INDEXING_STATUS = 'FETCH_INDEXING_STATUS';
export const START_REINDEX = 'START_REINDEX';

export const FETCH_PARTS_DATA = 'FETCH_PARTS_DATA';
export const FETCH_PART_CHAPTER_DATA = 'FETCH_PART_CHAPTER_DATA';
export const PART_SELECTED = 'PART_SELECTED';
export const ADD_PART = 'ADD_PART';
export const UPDATE_PART = 'UPDATE_PART';
export const DELETE_PART = 'DELETE_PART';
export const ADD_CHAPTER = 'ADD_CHAPTER';
export const UPDATE_CHAPTER = 'UPDATE_CHAPTER';
export const DELETE_CHAPTER = 'DELETE_CHAPTER';

export const FETCH_TOPIC_DATA = 'FETCH_TOPIC_DATA';
export const FETCH_TOPIC_CHECKLIST_DATA = 'FETCH_TOPIC_CHECKLIST_DATA';
export const TOPIC_SELECTED = 'TOPIC_SELECTED';
export const ADD_TOPIC = 'ADD_TOPIC';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const DELETE_TOPIC = 'DELETE_TOPIC';

export const GET_CHECKLIST = 'GET_CHECKLIST';
export const ADD_CHECKLIST = 'ADD_CHECKLIST';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';
export const PREVIEW_CHECKLIST = 'PREVIEW_CHECKLIST';
export const RESET_PREVIEW_CHECKLIST = 'RESET_PREVIEW_CHECKLIST';

export const POPUP_OPEN = 'POPUP_OPEN';
export const POPUP_CLOSE = 'POPUP_CLOSE';
export const ADD_PART_POPUP = 'ADD_PART_POPUP';
export const ADD_CHAPTER_POPUP = 'ADD_CHAPTER_POPUP';
export const ADD_TOPIC_POPUP = 'ADD_TOPIC_POPUP';
export const ADD_CHECKLIST_POPUP = 'ADD_CHECKLIST_POPUP';
export const EDIT_PART_POPUP = 'EDIT_PART_POPUP';
export const EDIT_CHAPTER_POPUP = 'EDIT_CHAPTER_POPUP';
export const EDIT_TOPIC_POPUP = 'EDIT_TOPIC_POPUP';
export const EDIT_CHECKLIST_POPUP = 'EDIT_CHECKLIST_POPUP';
export const DELETE_PART_POPUP = 'DELETE_PART_POPUP';
export const DELETE_CHAPTER_POPUP = 'DELETE_CHAPTER_POPUP';
export const DELETE_CHECKLIST_POPUP = 'DELETE_CHECKLIST_POPUP';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';

export const USER_UPDATED = 'USER_UPDATED';

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const FETCH_CONFIG  = 'FETCH_CONFIG';

export const CREATE_PROMO = 'CREATE_PROMO';
export const FETCH_PROMO  = 'FETCH_PROMO';

export const TYPES = {
    DRAGGABLETAG: 'DRAGGABLETAG'
};

export const GET_RECIPIENTS = 'GET_RECIPIENTS';
