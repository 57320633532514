import { POPUP_OPEN, POPUP_CLOSE } from '../constants/constants';

const defaultState = {
  popupIsOpened: false,
  currentPopup: '',
  data: {}
}

export default function popupsReducer(state=defaultState, action) {
  switch (action.type) {
    case POPUP_OPEN:
      return Object.assign({}, state, {
        popupIsOpened: true,
        currentPopup: action.currentPopup,
        data: action.data
      });
    case POPUP_CLOSE:
      return Object.assign({}, state, {
        popupIsOpened: false,
        currentPopup: ''
      });
    default:
      return state;

  }
}
