import React from 'react';
import { connect } from 'react-redux';
import DefaultDelete from './default-delete';
import { CHECKLISTS } from '../../constants/endpoints';
import { DELETE_CHECKLIST } from '../../constants/constants';

function DeleteChecklist({ _id, title, state, dispatch }){
  const checklist_id = _id.$oid;
  const deleteConfig = () => ({
    checklist: {
      id: checklist_id
    }
  });

  const noticeMessage = `You are about to delete the checklist (${title}).`

  const data = {
    sectionTitle: 'Checklist',
    endpoint: `${CHECKLISTS}/${checklist_id}`,
    action: DELETE_CHECKLIST,
    asyncAction: state.asyncAction,
    deleteConfig,
    dispatch,
    noticeMessage,
    title
  };

  return (
    <DefaultDelete
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(DeleteChecklist);
