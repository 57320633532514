import React from 'react';
import Styles from './tag-grid.css';

export default class TagGrid extends React.Component {
    render() {
        return (
            <ul id="tag-grid" className="tag-grid">
                {this.props.children}
            </ul>
        )
    }
}
