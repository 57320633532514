import {
  GET_SEARCH_RESULTS,
  UPDATE_TOPIC,
  DELETE_TOPIC,
  UPDATE_CHECKLIST,
  DELETE_CHECKLIST,
  UPDATE_PART,
  DELETE_PART,
  UPDATE_CHAPTER,
  DELETE_CHAPTER
} from '../constants/constants';

const defaultState = {
  data: [],
  total_count: 0,
  total_pages: 0,
  total_showing: 0
}

export default function SearchReducer(state=defaultState, action){
  switch(action.type) {
    case GET_SEARCH_RESULTS:
      return Object.assign({}, state, action.data);

    case UPDATE_PART:
    case UPDATE_CHAPTER:
    case UPDATE_TOPIC:
    case UPDATE_CHECKLIST:
      const dataWithUpdatedSearchItem = state.data.map( search => {
        if(search.item._id.$oid === action.data._id.$oid) {
          search.item.title = action.data.title;
        }
        return search;
      });

      return Object.assign({}, state, {
        data: dataWithUpdatedSearchItem
      });
    case DELETE_PART:
    case DELETE_CHAPTER:
    case DELETE_TOPIC:
    case DELETE_CHECKLIST:
      const dataWithoutDeletedSearchItem = state.data.filter(
        search => search.item._id.$oid !== action.data._id.$oid
      );

      return Object.assign({}, state, {
        data: dataWithoutDeletedSearchItem
      });
    case DELETE_CHECKLIST:
    default:
      return state
  }
}
