import React from 'react';
import classnames from 'classnames';
import ErrorNotice from '../notice/standard-error';
import SuccessNotice from '../notice/standard-success';
import { popUpClose } from '../../actions/pop-ups';
import { createData } from '../../actions/fetch-data';

import {
  SERVER_FAILURE_ERROR,
  SERVER_SUCCESS_CREATE
} from '../../constants/messages';

class DefaultAdd extends React.Component {

  handleSubmit(e){
    e.preventDefault();
    const title = e.target.querySelector('#title').value;
    const number = e.target.querySelector('#number').value;
    const description = e.target.querySelector('#description').value;
    const {endpoint, action, postData, dispatch} = this.props;

    dispatch(
      createData(
        endpoint,
        action,
        postData(title, number, description)
      )
    );
  }

  render(){
    const { sectionTitle, description, dispatch, asyncAction } = this.props;
    const {
      requestActive,
      updateSuccess,
      updateFailed
    } = asyncAction;

    const loadingClasses = classnames({
      'loading--left': true,
      hide: !requestActive
    });

    return (
      <div className="modal">
        <div className="modal-dialog">
          <form onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  aria-hidden="true"
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch( popUpClose() )
                  }}
                >&times;</button>
                <h4 className="modal-title">{`Add ${sectionTitle}`}</h4>
              </div>
              <div className="modal-body">
                <ErrorNotice msg={SERVER_FAILURE_ERROR} shouldDisplay={updateFailed} />
                <SuccessNotice msg={SERVER_SUCCESS_CREATE} shouldDisplay={updateSuccess} />
                <div className="pure-control-group">
                  <label htmlFor="number">Number:</label>
                  <input
                    id="number"
                    type="text"
                    placeholder="The number of the section. e.g. AA1"
                    className="form-control pure-input-1"
                    required="true"
                  />
                </div>
                <div className="pure-control-group">
                  <label htmlFor="title">Title:</label>
                  <input
                    id="title"
                    type="text"
                    placeholder="Please enter text here"
                    className="form-control pure-input-1"
                    required="true"
                  />
                </div>
                <div className="pure-control-group">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    id="description"
                    type="text"
                    placeholder="Please enter a description"
                    className="form-control pure-input-1"
                    required="true"
                  >{description}</textarea>
                </div>
              </div>
              <div className="modal-footer">
                <img
                  className={loadingClasses}
                  src={require('../../../images/loading-c.gif')}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch( popUpClose() )
                  }}
                  className="btn button-cancel pure-button"
                >Cancel</button>
                <button
                  type="submit"
                  className="btn button-primary pure-button"
                >Save changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default DefaultAdd;
