import {
  ATTEMPT_LOGIN,
  REQUEST_LOGIN_USER_ERROR,
  REQUEST_LOGIN_USER_INVALID,
  REQUEST_LOGIN_USER_SUCCESS
} from '../constants/constants';


const defaultState = {
  performingRequest: false,
  error: false,
  errMsg: {},
  loggedIn: false,
};

export default function loginReducer(state = defaultState, action){
  switch(action.type){
    case ATTEMPT_LOGIN:
      return Object.assign({}, state, {
        performingRequest: true
      });
    case REQUEST_LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        user: action.data,
        performingRequest: false,
        error: false,
        errMsg: {},
        loggedIn: true
      });
    case REQUEST_LOGIN_USER_ERROR:
      return Object.assign({}, state, {
        performingRequest: false,
        error: true,
        errMsg: action.errMsg
      });
    case REQUEST_LOGIN_USER_INVALID:
      return Object.assign({}, state, {
        performingRequest: false,
        error: true,
        errMsg: action.errMsg
      });

    default:
      return state;
  }
}
