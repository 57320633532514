import React from "react";
import { connect } from "react-redux";
import RichTextEditor from "react-rte";
import _ from "lodash";

import Header from "../components/headers/header";

import { retrieveData, createData } from "../actions/fetch-data";
import { CREATE_MESSAGE, GET_RECIPIENTS } from "../constants/constants";
import { SEND_MESSAGE, MESSAGE_RECIPIENTS } from "../constants/endpoints";

import "./message.scss";

class MessagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      sent: false,
      target: "",
      subject: "",
      content: RichTextEditor.createValueFromString(
        props.description || "",
        "html"
      ),
    };
  }

  componentDidMount() {
    const url = `${MESSAGE_RECIPIENTS}`;
    this.props.retrieveData(url, GET_RECIPIENTS);
  }

  sendMessage(e) {
    this.setState({ sending: true });
    e.preventDefault();
    let { target, subject, content } = this.state;
    content = content.toString("html");

    let { createData } = this.props;
    createData(SEND_MESSAGE, CREATE_MESSAGE, {
      message: { target, subject, content },
    });
    this.setState({ sent: true, sending: false });
  }

  render() {
    let { messages, asyncAction } = this.props.state;
    let account_groups = messages.recipients;

    if (Object.keys(account_groups).length === 0) {
      return (
        <div>
          <Header
            title="System message"
            subtitle="Contact users with essential system notifications"
          />
          <div className="content">
            <h2>No accounts subscribed</h2>
          </div>
        </div>
      );
    }

    let { target, subject, content, sent, sending } = this.state;

    const { updateSuccess, updateFailed } = asyncAction;

    return (
      <div>
        <Header
          title="System message"
          subtitle="Contact users with essential system notifications"
        />
        <div className="content">
          {sending ? (
            <img src={require("../../images/loading-c.gif")} />
          ) : (
            <form
              className="pure-form pure-form-stacked"
              readOnly={sent}
              onSubmit={this.sendMessage.bind(this)}
            >
              {updateSuccess || updateFailed ? (
                <div
                  className={`message notice ${
                    updateSuccess ? "notice__success" : ""
                  } ${updateFailed ? "notice__error" : ""}`}
                >
                  {updateSuccess ? "Message sent" : "Message could not be sent"}
                </div>
              ) : null}
              <div className="pure-control-group">
                <label htmlFor="target">Recipients</label>
                <select
                  required
                  className="pure-input-1 pure-select"
                  name="target"
                  readOnly={sent}
                  defaultValue={target}
                  onChange={(e) => this.setState({ target: e.target.value })}
                >
                  <option value="" disabled>
                    Please choose
                  </option>
                  {account_groups.map((ag, id) => (
                    <option key={id} value={ag.plan}>{`${ag.plan} (${
                      ag.count || 0
                    })`}</option>
                  ))}
                  <option value="-1">{`All (${messages.count})`}</option>
                </select>
              </div>

              <div className="pure-control-group">
                <label htmlFor="subject">Subject</label>
                <input
                  className="pure-input-1"
                  required
                  type="text"
                  name="subject"
                  readOnly={sent}
                  value={subject}
                  onChange={(e) => this.setState({ subject: e.target.value })}
                />
              </div>

              <div className="pure-control-group">
                <label htmlFor="message">Content</label>
                <RichTextEditor
                  readOnly={sent}
                  className="message-editor"
                  value={content}
                  onChange={(content) => this.setState({ content })}
                />
              </div>
              {target ? (
                <input
                  className="pure-button pure-button-primary"
                  type="submit"
                  readOnly={sent}
                  disabled={sent}
                  value={`${sent ? "Sent" : "Send"} to ${target} users`}
                  name="submit"
                />
              ) : null}
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default connect((nextState) => ({ state: nextState }), {
  retrieveData,
  createData,
})(MessagePage);
