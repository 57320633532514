import { Component } from 'react';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import { SEARCH } from '../../constants/pages';
import { DEFAULT_SEARCH_PAGE, PER_PAGE } from '../../constants/constants';

export default class SearchBox extends Component {
  handleSearch(e){
    e.preventDefault();
    const searchValue = this.refs.search.value;
    const searchQuery = queryString.stringify({
      q: searchValue || '*',
      page: DEFAULT_SEARCH_PAGE,
      per_page: PER_PAGE,
      filter: 'all'
    });

    return browserHistory.push(`${SEARCH}?${searchQuery}`);
  }

  render(){
    return (
      <form className="mt30" onSubmit={this.handleSearch.bind(this)}>
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon">
              <i className="fa fa-search" aria-hidden="true"></i>
            </span>
            <input ref="search" type="text" className="form-control" placeholder="Search for a Checklist, Topic, Chapter or Part" />
            <span className="input-group-btn">
              <button className="btn button-cancel pure-button" type="submit">Search</button>
            </span>
          </div>
        </div>
      </form>
    )
  }
}
