import React from 'react';
import { connect } from 'react-redux';
import DefaultDelete from './default-delete';
import { TOPICS } from '../../constants/endpoints';
import { DELETE_TOPIC } from '../../constants/constants';

function DeleteTopic({ _id, title, state, dispatch }){
  const topic_id = _id.$oid;
  const deleteConfig = () => ({
    part: {
      id: topic_id
    }
  });

  const noticeMessage = `By deleting the Topic (${title}) you will be deleting all checklists that belong to this topic as well.`

  const data = {
    sectionTitle: 'Topic',
    endpoint: `${TOPICS}/${topic_id}`,
    action: DELETE_TOPIC,
    asyncAction: state.asyncAction,
    deleteConfig,
    dispatch,
    noticeMessage,
    title
  };

  return (
    <DefaultDelete
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(DeleteTopic);
