import React from 'react';
import classnames from 'classnames';
import ErrorNotice from '../notice/standard-error';
import SuccessNotice from '../notice/standard-success';
import { popUpClose } from '../../actions/pop-ups';
import { deleteData } from '../../actions/fetch-data';

import {
  SERVER_FAILURE_ERROR,
  SERVER_SUCCESS_DELETE
} from '../../constants/messages';

class DefaultDelete extends React.Component {

  handleSubmit(e){
    e.preventDefault();
    const { endpoint, action, deleteConfig, dispatch } = this.props;

    dispatch(
      deleteData(
        endpoint,
        action,
        deleteConfig()
      )
    );
  }


  render(){
    const { title, sectionTitle, dispatch, asyncAction, noticeMessage} = this.props;
    const {
      requestActive,
      updateSuccess,
      updateFailed
    } = asyncAction;

    const loadingClasses = classnames({
      'loading--centered': true,
      hide: !requestActive
    });

    return (
      <div className="modal">
        <div className="modal-dialog">
          <form onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  aria-hidden="true"
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch( popUpClose() )
                  }}
                >&times;</button>
                <h4 className="modal-title">{`Delete ${sectionTitle}: ${title}`}</h4>
              </div>
              <div className="modal-body">
                <ErrorNotice msg={SERVER_FAILURE_ERROR} shouldDisplay={updateFailed} />
                <SuccessNotice msg={SERVER_SUCCESS_DELETE} shouldDisplay={updateSuccess} />
                <div className="pure-control-group">
                  <p className="c-red t-warning clearfix">
                    <i className="fa fa-exclamation-circle" aria-hidden="true" />
                    <span className="text">{' '}{noticeMessage}</span>
                  </p>
                  <p className="c-red t-warning tac">Are you sure you want to proceed?</p>
                </div>
              </div>
              <div className="modal-footer">
                <img
                  className={loadingClasses}
                  src={require('../../../images/loading-c.gif')}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch( popUpClose() )
                  }}
                  className="btn button-cancel pure-button"
                >Cancel</button>
                <button
                  type="submit"
                  disabled={updateSuccess}
                  className="btn button-error pure-button"
                >Proceed with changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

}

export default DefaultDelete;
