import React from "react";
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, browserHistory } from "react-router";
import { syncHistoryWithStore } from 'react-router-redux';
import { KEY } from './constants/constants';
import { isLoggedIn, removeSessionData } from './persistance/storage';

import LoggedInTemplate from './logged-in-template';
import LoggedOutTemplate from './logged-out-template';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard';
import Categories from './pages/categories';
import Content from './pages/content';
import Chapter from './pages/chapter';
import Users from './pages/users';
import Message from './pages/message';
import Search from './pages/search';
import Promo from "./pages/promo";

function requireAuth (path) {
  if(!Boolean(isLoggedIn(KEY)))
    return (routerState, replace) => replace(path);
}

function logout (nextState, replaceState) {
  removeSessionData();
  setTimeout( () => {
    window.location = '/';
  }, 50);
}

function redirectIfLoggedIn(path){
  if(sessionStorage.getItem('userData'))
    return (routerState, replace) => replace(path);
}


export default (store) => {
  const history = syncHistoryWithStore(browserHistory, store);

  return(
    <Provider store={store}>
      <Router history={history}>
        <Route component={ LoggedInTemplate } onEnter={requireAuth('/login')}>
          <IndexRoute component={ Dashboard } />
          <Route name="Dashboard" path="/dashboard" component={ Dashboard }  />
          <Route name="content" path="content" component={ Content } />
          <Route name="categories" path="categories">
            <IndexRoute  component={ Categories } />
            <Route name="chapters" path="chapters/:id" component={ Chapter } />
          </Route>
          <Route name="users" path="/users" component={ Users }  />
          <Route name="promo" path="/promo" component={ Promo }  />
          <Route name="message" path="/message" component={ Message }  />
          <Route name="search" path="/search" component={ Search }  />
        </Route>

        <Route component={ LoggedOutTemplate }>
          <Route name="Login" path="/" component={ Login } onEnter={redirectIfLoggedIn('/dashboard')}  />
          <Route name="Login" path="/login" component={ Login } onEnter={redirectIfLoggedIn('/dashboard')}  />
          <Route name="logout" path="/logout" onEnter={logout}  />
        </Route>

      </Router>
    </Provider>
  );
}
