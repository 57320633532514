import React from 'react';
import { listWithLinks } from '../../utils/content';

export default ({list}) => (
  <div className="checklist-section singposts">
    <header className="checklist-section--header">
      <div className="l-box">
        <h2>Signposts</h2>
      </div>
    </header>
    <ol>
      {listWithLinks(list)}
    </ol>
  </div>
)
