import {
  FETCH_CONFIG,
  UPDATE_CONFIG
} from '../constants/constants';

const defaultState = {
  video: false,
};

export default function configReducer(state=defaultState, action){
  switch (action.type) {
    case FETCH_CONFIG:
    case UPDATE_CONFIG:
      return Object.assign({}, state, action.data.config)
    default:
      return state;
  }
}
