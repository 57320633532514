import React from 'react';
import { connect } from 'react-redux';
import { TOPICS, CHAPTERS } from '../../constants/endpoints';
import { ADD_TOPIC, GET_ALL_CHAPTERS, GET_ALL_TOPICS } from '../../constants/constants';
import DefaultAddWithSelect from './default-add-with-select';

function AddTopic({dispatch, state }){
  const { asyncAction, bookSection } = state;
  const postData = (title, parent_id, number) => ({
    topic: {
      title,
      number,
      chapter_id: parent_id
    }
  });

  const chapters = bookSection.chapters.map( (chapter) => {
    return {
      title: chapter.title,
      id: chapter._id.$oid
    };
  });

  const data = {
    sectionTitle: 'Topic',
    endpoint: `${TOPICS}`,
    action: ADD_TOPIC,
    postData,
    dispatch,
    asyncAction,
    childSections: chapters,
    childSectionsEndPoint: CHAPTERS,
    childSectionsAction: GET_ALL_CHAPTERS,
    getAllCurrentAction: GET_ALL_TOPICS
  };

  return (
    <DefaultAddWithSelect
      {...data}
    />
  )
}

export default connect(nextState => ({ state: nextState }))(AddTopic);
