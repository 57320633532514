import React from "react";
import classnames from 'classnames';
import { connect } from 'react-redux'
import ErrorNotice from '../../components/notice/login-error';
import store from '../../store/store';

import './login.css';

import { attemptLogin } from '../../actions/login-action';

class LoginForm extends React.Component {

  handleSubmit(e) {
      e.preventDefault();
      const email = this.refs.email.value;
      const password = this.refs.password.value;
      const { attemptLogin} = this.props;
      const { dispatch } = store;

      attemptLogin({email, password, dispatch}, this.transitionToNextPath);
  }

  transitionToNextPath() {
    window.location.reload();
  }

  render() {
    const { performingRequest, error, errMsg } = this.props.state.login;
    const loadingClasses = classnames({
      hide: !performingRequest,
      loading: true
    });

    const errorState = {error, errMsg};

    return (
      <div id='login' onSubmit={this.handleSubmit.bind(this)}>
        <h1>Neurochecklists Admin</h1>
        <form className="pure-form pure-form-stacked" action="/login" method="post" autoComplete="off">
          <fieldset>
            <ErrorNotice hasError={errorState} />
            <div className="pure-control-group">
              <label htmlFor="email">
                {'Email'}
              </label>
              <input id="email" className="pure-input-1" placeholder="Email" type="email" name="email" ref="email">
              </input>
            </div>
            <div className="pure-control-group">
              <label htmlFor="password">
                {'Password'}
              </label>
              <input id="password" className="pure-input-1" placeholder="Password" type="password" name="password" ref="password">
              </input>
            </div>
            <div className="pure-controls">
              <button className="pure-button pure-button-primary" type="submit">
                <i className="fa fa-sign-in"></i>
                {'  '}
                {'Submit'}
              </button>
              <img className={loadingClasses} src={require('../../../images/loading-c.gif')} />
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}


export default connect(
  nextState => ({ state: nextState }),
  { attemptLogin }
)(LoginForm)
