const API_ROOT = `${process.env.API_URL}`;

export const LOGIN = `${API_ROOT}/users/sign_in`;
export const LOGOUT = `${API_ROOT}/users/sign_out`;
export const PARTS = `${API_ROOT}/parts`;
export const REORDER_PARTS = `${API_ROOT}/parts/reorder`;
export const CHAPTERS = `${API_ROOT}/chapters`;
export const TOPICS = `${API_ROOT}/topics`;
export const CHECKLISTS = `${API_ROOT}/checklists`;
export const CHECKLISTS_PREVIEW = `${API_ROOT}/checklists/preview`;
export const SEARCH = `${API_ROOT}/admin_search`;
export const AUTOCOMPLETE = `${API_ROOT}/autocomplete`;
export const FEEDBACK = `${API_ROOT}/feedback`;
export const USERS = `${API_ROOT}/users`;
export const USERS_DELETE = `${API_ROOT}/delete_account`;
export const STATS = `${API_ROOT}/stats`;
export const SEND_CONFIRMATIONS = `${API_ROOT}/resend_confirmations`;
export const INDEXING_STATUS = `${API_ROOT}/index/status`;
export const SUBSCRIPTIONS = `${API_ROOT}/subscriptions`;
export const CONFIG = `${API_ROOT}/admin/config`;
export const PROMO = `${API_ROOT}/admin/promo`;

export const SEND_MESSAGE = `${API_ROOT}/message`;
export const MESSAGE_RECIPIENTS = `${API_ROOT}/message/count`;
