import {
  PREVIEW_CHECKLIST,
  RESET_PREVIEW_CHECKLIST
} from '../constants/constants';

export function resetPreview(){
  return {
    type: RESET_PREVIEW_CHECKLIST
  }
}
