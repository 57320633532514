import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import store from "../../store/store";

import { SUBSCRIPTIONS } from "../../constants/endpoints";
import { USER_UPDATED } from "../../constants/constants";

import { createData } from "../../actions/fetch-data";

import "./account-switcher.scss";

class AccountSwitcher extends React.Component {
  constructor() {
    super();
    this.selectRef = React.createRef();
  }

  handleSubmit() {
    let { current, email } = this.props;
    let { dispatch } = store;

    let intended = this.selectRef.current.value;

    dispatch(
      createData(SUBSCRIPTIONS + "/change", USER_UPDATED, {
        current,
        intended,
        email,
      })
    );
  }

  render() {
    let { current, plans } = this.props;
    let { requestActive } = store.getState().asyncAction;
    let classList = classnames({
      'pure-button': true,
      'button-success': !requestActive
    });

    return (
      <div className="pure-form">
        <select
          ref={this.selectRef}
          className="pure-input m-r"
          defaultValue={current}
        >
          {plans.map((plan, id) => (
            <option key={id} value={plan}>{plan}</option>
          ))}
        </select>
        <button className={classList} disabled={requestActive} onClick={() => this.handleSubmit()}>Save</button>
      </div>
    );
  }
}

export default connect(
  (nextState) => ({ state: nextState }),
  {}
)(AccountSwitcher);
