import React from 'react';
import { Link } from 'react-router';
import { popupOpen } from '../../actions/pop-ups';
import { connect } from 'react-redux';

function Tag({
  data,
  url,
  sectionTitle,
  popupOpen,
  editChildAction,
  deleteChildAction
}){
  let linkItem = null;

  if(Boolean(url)){
    linkItem = (
      <Link to={`${url}/${data._slugs[0]}`}>
        <span className="c-green section-title">{sectionTitle.slice(0, -1)}</span>
        <span>{data.number + ' - ' + data.title}</span>
      </Link>
    );
  } else {
    linkItem = (
      <div>
        <span className="c-green section-title">{sectionTitle.slice(0, -1)}</span>
        <span>{data.number + ' - ' + data.title}</span>
      </div>
    )
  }

  return (
    <li className="tag-item clearfix">
      <div className="tag-item__text">
        {linkItem}
      </div>
      <span className='tag-item__badge tag-item__badge--delete' onClick={() => popupOpen(deleteChildAction, data)}>Delete</span>
      <span className='tag-item__badge' onClick={() => popupOpen(editChildAction, data)}>Edit</span>
    </li>
  )
}

export default connect(
  nextState => ({ state: nextState }),
  { popupOpen }
)(Tag)
