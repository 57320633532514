import React from 'react';
import AddSection from './add';
import {
  ADD_PART_POPUP,
  ADD_CHAPTER_POPUP,
  ADD_TOPIC_POPUP,
  ADD_CHECKLIST_POPUP
} from '../../constants/constants.js';

import { popupOpen } from '../../actions/pop-ups'

import store from '../../store/store';

export default () => (
  <div className="pure-g mt30">
    <div className="pure-u-1-2 pure-u-md-1-4">
      <div className="l-box">
        <AddSection
          title="Part"
          action={() => store.dispatch(popupOpen(ADD_PART_POPUP))}
        />
      </div>
    </div>

    <div className="pure-u-1-2 pure-u-md-1-4">
      <div className="l-box">
        <AddSection
          title="Chapters"
          action={() => store.dispatch(popupOpen(ADD_CHAPTER_POPUP))}
        />
      </div>
    </div>

    <div className="pure-u-1-2 pure-u-md-1-4">
      <div className="l-box">
        <AddSection
          title="Topics"
          action={() => store.dispatch(popupOpen(ADD_TOPIC_POPUP))}
        />
      </div>
    </div>

    <div className="pure-u-1-2 pure-u-md-1-4">
      <div className="l-box">
        <AddSection
          title="Checklist"
          action={() => store.dispatch(popupOpen(ADD_CHECKLIST_POPUP))}
        />
      </div>
    </div>
  </div>
)
