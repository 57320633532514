import ExpandingTagItem from './expanding-tag';
import { DragSource, DropTarget } from 'react-dnd';
import { TYPES } from '../../constants/constants';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom'


const dragSource = {
  beginDrag: (props, monitor, component) => {
    const item = { number: props.data.number, id: props.data._id.$oid };
    return item;
  },
  endDrag: (props, monitor, component) => {
    props.dragStop();
  },
  isDragging: function(props, monitor) {
    return props.data._id.$oid === monitor.getItem().id;
  },
};

const dragTarget = {
  hover: (props, monitor, component) => {
    const dragIndex = monitor.getItem().number;
    const hoverIndex = props.data.number;
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.moveItem(dragIndex, hoverIndex)
  }
}

@DragSource(TYPES.DRAGGABLETAG, dragSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
@DropTarget(TYPES.DRAGGABLETAG, dragTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))
export default class DraggableExpandingTagItem extends React.Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    moveItem: PropTypes.func.isRequired,
    dragStop: PropTypes.func.isRequired,
    data: PropTypes.shape({
      sort: PropTypes.number,
      _id: PropTypes.object.isRequired
    })
  }

  render() {
    // These props are injected by React DnD,
    // as defined by your `collect` function above:
    const { connectDragSource, connectDropTarget, isDragging } = this.props;
    const css = {opacity : isDragging ? 0.2 : 1}

    return connectDragSource(
      connectDropTarget(<div style={css}><ExpandingTagItem {...this.props} /></div>)
    )
  }
}
