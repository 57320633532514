import {
  GET_RECIPIENTS
} from "../constants/constants";

const defaultState = {
  recipients: [],
  count: 0,
};

export default function MessagesReducer(state=defaultState, action){
  switch (action.type) {
    case GET_RECIPIENTS:
      return {
        ...state,
        recipients: action.data.recipients,
        count: action.data.total,
      };
    default:
      return state;
  }
}
