import {
  GET_USERS_DATA,
  DELETE_USER,
  SEND_CONFIRMATION_REMINDER,
  USER_UPDATED
} from '../constants/constants';

const defaultState = {
  remindersSent: false,
  data: [],
  plans: [],
  totalPages: 1
};

export default function UsersReducer(state=defaultState, action){
  switch (action.type) {
    case SEND_CONFIRMATION_REMINDER:
      return {...state, remindersSent: action.data.success };
    case GET_USERS_DATA:
      return {...state, data: action.data.data, totalPages: action.data.total_pages }
    case DELETE_USER:
      const users = state.data.filter( item => action.data._id.$oid !== item._id.$oid )
      return {...state, data: users }
    case USER_UPDATED:
      const adjusted = state.data.map(user => {
        if (user.email === action.data.email) {
          user.account_type = action.data.account;
        }

        return user;
      });
      return {...state, data: adjusted }
    default:
      return state;
  }
}
