import {
  PREVIEW_CHECKLIST,
  RESET_PREVIEW_CHECKLIST,
  ADD_CHECKLIST,
  UPDATE_CHECKLIST,
  EDIT_CHECKLIST_POPUP,
  ADD_CHECKLIST_POPUP
} from '../constants/constants';

const defaultState = {
  hasBeenPreviewed: false,
  hasBeenSubmited: false,
  data: {}
};

export default function ChecklistReducer( state=defaultState, action){
  switch (action.type) {
    case PREVIEW_CHECKLIST:
      return Object.assign({}, state, {
        data: action.data,
        hasBeenPreviewed: true
      });
    case RESET_PREVIEW_CHECKLIST:
      return Object.assign({}, state, {
        data: {},
        hasBeenPreviewed: false,
        hasBeenSubmited: false
      });
    case ADD_CHECKLIST:
    case UPDATE_CHECKLIST:
      return Object.assign({}, state, {
        hasBeenSubmited: true
      });

    default:
      return state;
  }
}
