import React from 'react';
import moment from 'moment'

import { popUpClose } from '../../actions/pop-ups';
import { userUpdated } from '../../actions/pop-ups';
import AccountSwitcher from '../user/account-switcher';

import store from '../../store/store';

export default function ViewUser({
  name,
  email,
  account_type,
  searches_left,
  created_at,
  confirmed,
  last_sign_in_at,
  sign_in_count,
  subscription,
}){
  let plans = [
    'Starter',
    'Monthly Premium',
    'Yearly Premium'
  ];

  return(
    <div className="modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={(e) => {
                e.preventDefault()
                store.dispatch( popUpClose() )
              }}
            >&times;</button>
            <h4 className="modal-title">User</h4>
          </div>
          <div className="modal-body">
            <div className="crud-form__container user-view-table">
              <table width="100%" className="pure-table pure-table-horizontal pure-table-striped">
                <tbody>
                  <tr>
                    <td><strong>Name:</strong></td>
                    <td>{name}</td>
                  </tr>
                  <tr>
                    <td><strong>Email:</strong></td>
                    <td><a href={'mailto:' + email}>{email}</a></td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Account Type:</strong>
                    </td>
                    <td>
                      <AccountSwitcher email={email} current={account_type} plans={plans} onChanged={(user) => store.dispatch( userUpdated(user) )} />
                    </td>
                  </tr>

                  {
                    (account_type == "Starter") ? (
                      <tr>
                        <td><strong>Searches left:</strong></td>
                        <td>{searches_left > 100 ? "Unlimited until next billing date": searches_left}</td>
                      </tr>
                    ) : null
                  }
                  {
                    subscription ? (
                      <React.Fragment>
                        <tr>
                          <td><strong>Subscription status:</strong></td>
                          <td>
                              <span className="subscription-status">{subscription.status}</span>
                        </td>
                        </tr>
                        <tr>
                          <td><strong>Payment method</strong></td>
                          <td>
                              <img src={subscription.payment_method.image_url} alt="payment method symbol"/>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Last Transaction</strong></td>
                          <td>
                            <span className="">£{ subscription.latest_transaction.amount } ({ subscription.latest_transaction.status })</span>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Next billing date</strong></td>
                          <td>
                            <span className="">{ subscription.next_billing_date }</span>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Next billing amount</strong></td>
                          <td>
                            <span className="">£{ subscription.next_billing_period_amount }</span>
                          </td>
                        </tr>
                      </React.Fragment>
                    ): null
                  }
                  <tr>
                    <td><strong>Date Joined:</strong></td>
                    <td>{moment(created_at).format('MMMM Do YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Confirmed Account:</strong></td>
                    <td>{confirmed ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr>
                    <td><strong>Last signed in:</strong></td>
                    <td>{ last_sign_in_at ? moment(last_sign_in_at).fromNow() : 'Never'}</td>
                  </tr>
                  <tr>
                    <td><strong>Sign in count:</strong></td>
                    <td>{sign_in_count}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={(e) => {
                e.preventDefault();
                store.dispatch( popUpClose() )
              }}
              className="btn button-cancel pure-button"
            >Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}
