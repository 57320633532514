import fetch from 'isomorphic-fetch';
import store from '../store/store';

import {
  apiPostConfig,
  apiPostFileConfig,
  fetchData
} from '../utils/data';

import {
  isLoggedIn,
  getSessionToken,
  getSessionUser
} from '../persistance/storage';

import {
  KEY,
  ASYNC_REQUEST,
  ASYNC_REQUEST_COMPLETE,
  ASYNC_REQUEST_ERROR,
  ASYNC_REQUEST_UPDATE_SUCCESS,
  ASYNC_REQUEST_ACTION_FAILED
} from '../constants/constants';

import { SERVER_FAILURE_ERROR } from '../constants/messages';


export function retrieveData(endpoint, actionType, id=null){

  const headers = requestHeaders(isLoggedIn(KEY));

  performRequest(endpoint, headers, actionType, id);

  return { type: ASYNC_REQUEST };
}

export function createData(endpoint, actionType, data){
  const credentials = requestHeaders( isLoggedIn(KEY) );
  const config = apiPostConfig('POST', credentials ,data);

  processData(endpoint, config, actionType);

  return { type: ASYNC_REQUEST };
}

export function updateData(endpoint, actionType, data, id){
  const credentials = requestHeaders( isLoggedIn(KEY) );
  const config = apiPostConfig('PATCH', credentials ,data);

  processData(endpoint, config, actionType, id);

  return { type: ASYNC_REQUEST };
}

export function deleteData(endpoint, actionType, data, id){
  const credentials = requestHeaders( isLoggedIn(KEY) );
  const config = apiPostConfig('DELETE', credentials ,data);

  processData(endpoint, config, actionType, id);

  return { type: ASYNC_REQUEST };
}


export function createDataFile(endpoint, actionType, data){
  const credentials = requestHeaders( isLoggedIn(KEY) );
  const config = apiPostFileConfig('POST', credentials ,data);

  processData(endpoint, config, actionType);

  return { type: ASYNC_REQUEST };
}

export function updateDataFile(endpoint, actionType, data){
  const credentials = requestHeaders( isLoggedIn(KEY) );
  const config = apiPostFileConfig('PUT', credentials ,data);

  processData(endpoint, config, actionType);

  return { type: ASYNC_REQUEST };
}


function performRequest(endpoint, headers, actionType, id){
  const { dispatch } = store;

  fetchData(endpoint, headers)
    .then( data => dispatch({ type: actionType, data, id }) )
    .catch( error => {
      dispatch({ type: ASYNC_REQUEST_ERROR, error })
    })
    .then( () => { dispatch({ type: ASYNC_REQUEST_COMPLETE }) })
}

function processData(endpoint, config, actionType, id=null){
  const { dispatch } = store;

  fetch(endpoint, config)
    .then( res => {
      if(res.ok){
        return res.json();
      } else {
        throw {error: SERVER_FAILURE_ERROR };
      }
    })
    .then( data => {
      dispatch({ type: ASYNC_REQUEST_UPDATE_SUCCESS })
      dispatch({ type: actionType, data, id })
    })
    .catch( error => dispatch({ type: ASYNC_REQUEST_ACTION_FAILED }) )
    .then( () => dispatch({ type: ASYNC_REQUEST_COMPLETE }) )
}

function requestHeaders(isLoggedIn){
  if(isLoggedIn){
    let userEmail = getSessionUser(KEY);
    let userToken = getSessionToken(KEY);

    return {
      "X-USER-EMAIL" : userEmail,
      "X-USER-TOKEN" : userToken
    };
  }

  return {};
}
