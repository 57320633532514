import React from 'react';
import {Link} from 'react-router';
import classnames from 'classnames';


export default ({location, totalShowing, totalCount, totalPages, requestActive})=> {
  let { query } = location;
  let PER_PAGE = query.per_page;
  let pageNumber = query.page || 1;
  let searchTitle = query && query.q ? query.q  : '';
  let resultsText = "";
  let currentlyShowing = ""
  let curentOffset = totalShowing * pageNumber;
  let loadingClasses = classnames({
    'page-data-loading': true,
    'page-data-loading--finished': !requestActive
  });

  if (pageNumber === 1){
    currentlyShowing = 1
  } else {
    currentlyShowing = ((pageNumber * totalShowing ) - (totalShowing - 1));
  }

  if(pageNumber == totalPages  ) {
    currentlyShowing = (totalCount - totalShowing );
    curentOffset = totalCount;
  }


  if(totalPages > 1){
    resultsText = <p className="results-text">{`Showing results ${currentlyShowing} to ${curentOffset} of ${totalCount} for ${searchTitle}`}</p>
  } else {
    resultsText = <p className="results-text">{`Showing results ${totalShowing} of ${totalCount} for ${searchTitle}`}</p>
  }


  return(
    <header className="header search-results-header">
      <h1>{`Search Results: ${searchTitle}`}</h1>
      <h2>{resultsText}</h2>
      <img
        className={loadingClasses}
        src={require('../../../images/loading-c.gif')}
      />
    </header>
  )
}
