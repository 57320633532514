import React from 'react';
import { connect } from 'react-redux';
import DefaultEdit from './default-edit';
import { CHAPTERS } from '../../constants/endpoints';
import { UPDATE_CHAPTER } from '../../constants/constants';

function EditChapter({ _id, title, number, description, video, state, dispatch }){

  const chapter_id = _id.$oid;
  const putData = (title, number, description, video) => ({
    chapter: {
      title,
      number,
      description,
      video,
    }
  });

  var data = {
    sectionTitle: 'Chapter',
    section_id: chapter_id,
    endpoint: `${CHAPTERS}/${chapter_id}`,
    action: UPDATE_CHAPTER,
    asyncAction: state.asyncAction,
    putData,
    dispatch,
    title,
    description,
    video,
    number
  };

  return (
    <DefaultEdit {...data} />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(EditChapter);
