import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import login from './login';
import parts from './parts';
import topics from './topics';
import checklist from './checklists-preview';
import retrieveData from './fetch-data';
import popups from './pop-ups';
import bookSection from './book-sections';
import searches from './searches';
import users from './users';
import messages from './messages';
import stats from './stats';
import indexing from './indexing';
import config from './config';
import promo from './promo';

export default combineReducers({
  popups,
  login,
  parts,
  users,
  topics,
  checklist,
  bookSection,
  searches,
  stats,
  indexing,
  config,
  promo,
  messages,
  asyncAction: retrieveData,
  routing: routerReducer
});
