import React from "react";
import { connect } from 'react-redux';
import classnames from 'classnames';
import { retrieveData, createData } from '../actions/fetch-data';
import { part_selected } from '../actions/parts';
import { REORDER_PARTS, PARTS, CHAPTERS } from '../constants/endpoints';
import {
  FETCH_PARTS_DATA,
  FETCH_PART_CHAPTER_DATA,
  PART_SELECTED,
  EDIT_PART_POPUP,
  DELETE_PART_POPUP,
  EDIT_CHAPTER_POPUP,
  UPDATE_PART,
  DELETE_CHAPTER_POPUP
} from '../constants/constants';
import TagGrid from '../components/tags/tag-grid';
import DraggableExpandingTagItem from '../components/tags/draggable-expanding-tag';
import AddSections from '../components/add/add-sections';
import Header from '../components/headers/header';
import SearchBox from '../components/search/search';
import update from 'immutability-helper';
import store from '../store/store';

class CategoriesPage extends React.Component {
  constructor(props) {
    super(props)
    this.moveItem = this.moveItem.bind(this)
    this.dragStop = this.dragStop.bind(this)
    this.putData = this.putData.bind(this)
  }

  componentDidMount(){
    this.props.retrieveData(PARTS, FETCH_PARTS_DATA);
  }

  fetchChildrenChapters(id, url, actionType){
    this.props.part_selected(id);
    this.props.retrieveData(url, actionType, id);
  }
  moveItem(dragIndex, hoverIndex) {
      let hovered = 0;
      let dragger = 0;

      let reorderedParts = this.props.state.parts.parts;
      reorderedParts.some((item, i) => {
        if (item.sort == hoverIndex) {
          hovered = i;
        }
        if (item.sort == dragIndex) {
          dragger = i;
        }

        if (hovered && dragger) {
          return true;
        }
      });

      // move the dragging item to be next to the hovered item
      reorderedParts.splice(hovered, 0, reorderedParts.splice(dragger, 1)[0]);
      this.props.state.parts.parts = reorderedParts;
      this.setState(this.props.state);
    }

    putData(parts) {
      // update number for each part to match the new array order
      return {
        parts: parts.map((part, i) => {
          part.sort = i+1;
          return { id: part._id.$oid, sort: part.sort };
        })
      };
    }

    dragStop() {
      // dispatch update for the changed parts parts
      store.dispatch(
        createData(
          REORDER_PARTS,
          UPDATE_PART,
          this.putData(this.props.state.parts.parts)
        )
      );
    }
  render(){
    const { requestActive } = this.props.state.asyncAction;
    const { parts } = this.props.state.parts;

    let tags = parts.map(( part, index ) => {
      return (
        <DraggableExpandingTagItem
          key={index}
          data={part}
          childTitle='Chapters'
          editAction={EDIT_PART_POPUP}
          deleteAction={DELETE_PART_POPUP}
          editChildAction={EDIT_CHAPTER_POPUP}
          deleteChildAction={DELETE_CHAPTER_POPUP}
          moveItem={this.moveItem}
          dragStop={this.dragStop}
          onSelect={
            this.fetchChildrenChapters.bind(
              this,
              part._id.$oid,
              `${PARTS}/${part._id.$oid}/chapters`,
              FETCH_PART_CHAPTER_DATA
            )
          }
          childUrl={'/categories/chapters'}
        ></DraggableExpandingTagItem>)
    });

    return (
      <div>
        <Header title="Categories" subtitle="View Add Edit & Delete"/>
        <div className="content">
          <SearchBox />
          <div className="mobile-hide">
            <AddSections />
          </div>
          <h2>Categories</h2>
          <TagGrid>
            {tags}
          </TagGrid>
        </div>
      </div>
    );
  }
}

export default connect(
  nextState => ({ state: nextState }),
  { retrieveData, part_selected}
)(CategoriesPage)
