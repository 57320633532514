import React from 'react';
import { connect } from 'react-redux';
import DefaultDelete from './default-delete';
import { PARTS } from '../../constants/endpoints';
import { DELETE_PART } from '../../constants/constants';

function DeletePart({ _id, title, state, dispatch }){
  const part_id = _id.$oid;
  const deleteConfig = () => ({
    part: {
      id: part_id
    }
  });

  const noticeMessage = `By deleting the Part (${title}) you will be deleting all children that belong to this part. This includes all Chapters, Topic and Checklists belonging to it.`

  const data = {
    sectionTitle: 'Part',
    endpoint: `${PARTS}/${part_id}`,
    action: DELETE_PART,
    asyncAction: state.asyncAction,
    deleteConfig,
    dispatch,
    noticeMessage,
    title
  };

  return (
    <DefaultDelete
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(DeletePart);
