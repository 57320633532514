import React from 'react';
import { connect } from 'react-redux';
import DefaultDelete from './default-delete';
import { USERS_DELETE } from '../../constants/endpoints';
import { DELETE_USER } from '../../constants/constants';

function DeleteUser({ _id, name, email, state, dispatch }){
  const user_id = _id.$oid;
  const deleteConfig = () => ({
    account: {
      email
    }
  });

  const noticeMessage = `You are about to delete the account for the user named: ${name}. This process is irreversible and will unsubscribe them from their billing plan and permanately delete their account.`;
  const data = {
    sectionTitle: 'User',
    endpoint: `${USERS_DELETE}/${user_id}`,
    action: DELETE_USER,
    asyncAction: state.asyncAction,
    deleteConfig,
    dispatch,
    noticeMessage,
    title: name
  };

  return (
    <DefaultDelete
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(DeleteUser);
