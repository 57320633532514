import React from 'react';
import { connect } from 'react-redux';
import {
  TOPICS,
  CHECKLISTS,
  CHECKLISTS_PREVIEW
} from '../../constants/endpoints';
import {
  ADD_CHECKLIST,
  GET_ALL_TOPICS,
  PREVIEW_CHECKLIST
} from '../../constants/constants';
import DefaultAddWithPreview from './default-add-with-preview';

function AddChecklist({ dispatch, state }){
  const { asyncAction, bookSection } = state;
  const postData = (file, parent_id) => {
    var data = new FormData();
    data.append("checklist[file]", file, 'checklist.docx');
    data.append("checklist[topic_id]", parent_id);
    return data;
  }

  const topics = bookSection.topics.map( (topic) => {
    return {
      title: topic.title,
      id: topic._id.$oid
    };
  });

  const data = {
    sectionTitle: 'Checklist',
    previewEndPoint: CHECKLISTS_PREVIEW,
    addEndpoint: CHECKLISTS,
    previewAction: PREVIEW_CHECKLIST,
    addAction: ADD_CHECKLIST,
    postData,
    dispatch,
    asyncAction,
    childSections: topics,
    childSectionsEndPoint: TOPICS,
    childSectionsAction: GET_ALL_TOPICS,
    previewData: state.checklist
  };

  return (
    <DefaultAddWithPreview
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(AddChecklist);
