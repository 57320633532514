import React from 'react';
import Header from './header';
import Section from './section';
import Signposts from './singposts';

import './checklist.css';

export default ({data}) => {
  const {title, sections, signposts} = data;
  const checklist_sections = sections.map( (section, index) => {
    return (
      <Section
        key={index}
        title={section.title}
        list={section.list_items}
      />
    );
  });

  return (
    <div>
      <Header title={title.title} />
      {checklist_sections}
      {(signposts)? (<Signposts list={signposts}/>): <div /> }
    </div>
  )
}
