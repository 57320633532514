import React from 'react';
import TagExpander from './tag-expander';
import classnames from 'classnames';
import { popupOpen } from '../../actions/pop-ups';
import { connect } from 'react-redux';

import './tag.css';

class ExpandingTagItem extends React.Component {
  render() {
    let { data, onSelect, childTitle, childUrl, editAction, deleteAction, editChildAction, deleteChildAction, popupOpen } = this.props;

    const child_sections = data[`${childTitle.toLowerCase()}`];
    const tagItem = classnames({
      clearfix: true,
      'tag-item--selected': data.selected,
      'tag-item': true
    });

    return (
      <li>
        <div className={tagItem}>
          <div className="tag-item__text" onClick={onSelect}>
            {data.number} - {data.title}
          </div>
          <div className="tag-item__badge tag-item__badge--delete" onClick={() => popupOpen(deleteAction, data)}>
            Delete
          </div>
          <div className="tag-item__badge" onClick={() => popupOpen(editAction, data)}>
            Edit
          </div>
        </div>
        <div>
          {data.selected && child_sections.length ?
            <TagExpander title={childTitle} data={child_sections} editChildAction={editChildAction} deleteChildAction={deleteChildAction} url={childUrl} />
            :
            ''
          }
        </div>
      </li>
    );
  }
}

export default connect(nextState => ({ state: nextState }), { popupOpen })(ExpandingTagItem);
