import {
  FETCH_TOPIC_DATA,
  FETCH_TOPIC_CHECKLIST_DATA,
  TOPIC_SELECTED,
  ADD_TOPIC,
  UPDATE_TOPIC,
  DELETE_TOPIC,
  ADD_CHECKLIST,
  UPDATE_CHECKLIST,
  DELETE_CHECKLIST
} from '../constants/constants';

const defaultState = {
  topics: []
};

export default function topicReducer(state=defaultState, action){
  switch (action.type) {
    case FETCH_TOPIC_DATA:
      const topics = action.data.map((item)=>{
        return Object.assign({}, item, {
          selected: false,
          checklists: []
        })
      });
      return Object.assign({}, state, {
        topics
      });

    case TOPIC_SELECTED:
      const selected_topic = state.topics.map( topic => {
        if(topic._id.$oid === action.id)
          topic.selected = !topic.selected;
        return topic;
      });

      return Object.assign({}, state, {
        topics: selected_topic
      });

    case ADD_TOPIC:
      const topicAdded = state.topics.concat(action.data);

      return Object.assign({}, state, {
        topics: topicAdded
      });

    case UPDATE_TOPIC:
      const topicsWithUpdatedTopic = state.topics.map( topic => {
        if(topic._id.$oid === action.data._id.$oid) {
          topic.title = action.data.title;
        }
        return topic;
      });

      return Object.assign({}, state, {
        topics: topicsWithUpdatedTopic
      });

    case DELETE_TOPIC:
      const topicsWithoutDeletedTopic = state.topics.filter(
        topic => topic._id.$oid !== action.data._id.$oid
      );

      return Object.assign({}, state, {
        topics: topicsWithoutDeletedTopic
      });

    case FETCH_TOPIC_CHECKLIST_DATA:
      const topicWithFetchedChecklists = state.topics.map( topic => {
        if(topic._id.$oid === action.id) {
          topic.checklists = action.data;
        }
        return topic;
      });

      return Object.assign({}, state, {
        topics: topicWithFetchedChecklists
      });


    case ADD_CHECKLIST:
      const topicsWithAddedChecklist = state.topics.map( topic => {
        if(topic._id.$oid === action.data.topic_id.$oid){
          if(topic.checklists)
            topic.checklists = topic.checklists.concat(action.data);
          else
            topic.checklists = [].concat(action.data);
        }
        return topic;
      });
      return Object.assign({}, state, {
        topics: topicsWithAddedChecklist
      });

    case UPDATE_CHECKLIST:
      const topicsWithUpdatedChecklist = state.topics.map( topic => {
        if(topic._id.$oid === action.data.topic_id.$oid){
          topic.checklists = topic.checklists.map( checklist => {
            if(checklist._id.$oid == action.data._id.$oid){
              checklist = action.data;
            }
            return checklist;
          });
        }
        return topic;
      });

      return Object.assign({}, state, {
        topics: topicsWithUpdatedChecklist
      });

    case DELETE_CHECKLIST:
      const topicsWithoutDeletedChecklist = state.topics.map( topic => {
        if(topic._id.$oid == action.data.topic_id.$oid){
          topic.checklists = topic.checklists.filter(
            checklist => checklist._id.$oid !== action.data._id.$oid
          );
        }
        return topic;
      })

      return Object.assign({}, state, {
        topics: topicsWithoutDeletedChecklist
      });

    default:
      return state;
  }
}
