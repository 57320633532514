import React from 'react';
import { connect } from 'react-redux';

import DefaultEdit from './default-edit';
import { TOPICS } from '../../constants/endpoints';
import { UPDATE_TOPIC } from '../../constants/constants';


function EditTopic({ _id, state, dispatch, title, video, number, description }){
  const topic_id = _id.$oid;
  const putData = (title, number, description, video) => ({
    topic: {
      title,
      number,
      description,
      video,
    }
  });

  const data = {
    sectionTitle: 'Topic',
    section_id: topic_id,
    endpoint: `${TOPICS}/${topic_id}`,
    action: UPDATE_TOPIC,
    asyncAction: state.asyncAction,
    putData,
    dispatch,
    title,
    number,
    description,
    video,
  };

  return (
    <DefaultEdit
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState }),
)(EditTopic);
