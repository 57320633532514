import React from "react";
import classnames from 'classnames';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import { retrieveData } from '../actions/fetch-data';
import { popupOpen } from '../actions/pop-ups';
import Header from '../components/headers/header';
import Pagination from '../components/search/search-pagination';
import SortButtons from '../components/tables/sort-buttons';

import {
  GET_USERS_DATA,
  VIEW_USER_POPUP,
  DELETE_USER_POPUP
} from '../constants/constants';
import { USERS } from '../constants/endpoints';


class UsersPage extends React.Component {
  componentDidMount(){
    const { query } = this.props.location;
    const searchParams = queryString.stringify(query);
    const url = `${USERS}?${searchParams}`;

    this.props.retrieveData(url, GET_USERS_DATA);
  }

  renderUsers(){

    return this.props.state.users.data.map( (user, index) => {
      const confirmedClasses = classnames({
        'confirmed-account': true,
        'fa': true,
        'fa-check-circle': user.confirmed,
        'fa-times-circle': !user.confirmed,
        'c-success': user.confirmed,
        'c-error': !user.confirmed
      });

      return (
        <tr key={index}>
          <td>{user.name}</td>
          <td>
            {user.email}
            <i className={confirmedClasses} aria-hidden="true"></i>
          </td>
          <td>{user.account_type}</td>
          <td>{user.subscription ? user.subscription.status : '-'}</td>
          <td>{moment(user.created_at).format('MMMM Do YYYY')}</td>
          <td>
            <span
              className="tag-item__badge"
              onClick={() => this.props.popupOpen(VIEW_USER_POPUP, user)}
            >View</span>
            <span
              className="tag-item__badge tag-item__badge--delete"
              onClick={() => this.props.popupOpen(DELETE_USER_POPUP, user)}
            >Delete</span>
          </td>
        </tr>
      )
    })
  }

  render(){
    return (
      <div>
        <Header title="Users" subtitle="Manage users"/>
        <div className="content">
          <table width="100%" className="pure-table pure-table-horizontal pure-table-striped user-table">
            <thead>
                <tr>
                  <th>Name <SortButtons searchKey="name" /></th>
                  <th>Email <SortButtons searchKey="email" /></th>
                  <th>Account Type <SortButtons searchKey="account_type" /></th>
                  <th>Subscription Status <SortButtons searchKey="subscription_status" /></th>
                  <th>Date Joined <SortButtons searchKey="created_at" /></th>
                  <th>Delete</th>
                </tr>
            </thead>

            <tbody>
              {this.renderUsers()}
            </tbody>
          </table>

          {
            (this.props.state.asyncAction.requestActive)?
            null :
            (
              <Pagination
                totalPages={this.props.state.users.totalPages}
                location={this.props.location}
              />
            )
          }
        </div>
      </div>
    )
  }
}


export default connect(
  nextState => ({ state: nextState }),
  { retrieveData, popupOpen }
)(UsersPage)
