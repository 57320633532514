import React from "react";

export default ({shouldDisplay, msg}) => {
  if(shouldDisplay){
    return (
      <div className="notice notice__success">
        <span>{msg}</span>
      </div>
    )
  } else {
    return <div/>
  }

}
