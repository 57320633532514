import CryptoJS from 'crypto-js';


export const storeSessionUser = (key, data) => {
  if(!data || !key) return;
  const userData = JSON.stringify(data);
  const ciphertext = CryptoJS.AES.encrypt(userData, key);
  sessionStorage.setItem('userData', ciphertext.toString());
}

export const getSessionToken = (key) => {
  let data = sessionStorage.getItem('userData');
  if(!data || !key) return false;
  return decryptData(key, data)["authentication_token"]
};

export const getSessionUser = (key) => {
  let data = sessionStorage.getItem('userData');
  if(!data || !key) return false;
  return decryptData(key, sessionStorage.getItem('userData'))["email"]
};

export const decryptData = (key, data) => {
  if(!data || !key) return {};
  var bytes  = CryptoJS.AES.decrypt(data, key);
  var plaintext = bytes.toString(CryptoJS.enc.Utf8);

  return JSON.parse(plaintext);
}

export const getSessionUserData = (key) => {
  if( !key ) return;
  let data = sessionStorage.getItem('userData');
  return decryptData(key, data);
}

export const removeSessionData = () => {
  sessionStorage.removeItem('userData');
}

export const updateSessionUserData = (key, data) => {
  let userData = getSessionUserData(key);
  let newData = Object.assign(userData, data);
  storeSessionUser(key, newData);
}


export const isLoggedIn = (key) => {
  if(sessionStorage.getItem('userData') && getSessionToken(key) ) return true;
  return false;
}
