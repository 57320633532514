import React from "react";
import { Link } from "react-router";
import { USER_PER_PAGE } from '../../constants/constants';

export default () => (
  <div id="menu">
    <nav className="pure-menu">
      <a className="pure-menu-heading" href="/dashboard"> Admin </a>
      <ul className="pure-menu-list">
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to="/dashboard" activeClassName='pure-menu-selected'>Dashboard</Link>
          </li>
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to="/content" activeClassName='pure-menu-selected'>Content</Link>
          </li>
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to="/categories" activeClassName='pure-menu-selected'>Database</Link>
          </li>
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to={`/users?page=1&per_page=${USER_PER_PAGE}`} activeClassName='pure-menu-selected'>Users</Link>
          </li>
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to={`/promo`} activeClassName='pure-menu-selected'>Promo Codes</Link>
          </li>
          <li className="pure-menu-item">
            <Link className="pure-menu-link" to="/message" activeClassName="pure-menu-selected">Message</Link>
          </li>
          <li className="pure-menu-item pure-menu-item">
            <br />
            <br />
          </li>
          <li className="pure-menu-item.pure-menu-logout">
            <a className="pure-menu-link" href="/logout">
              <i className="fa fa-sign-out"/>
              {"Logout"}
            </a>
          </li>
      </ul>
    </nav>
  </div>
)
