import React from "react";
import StaticContainer from 'react-static-container';
import Navigation from './components/navigation/navigation';
import Popups from './components/popups/popups';
import IndexingStatus from "./components/indexing/indexing-status";

import '../css/layout.css';

export default (props) => {
  window.scrollTo(0, 0);

  return (
    <div id="layout">
      <a href="#menu" id="menuLink" className="menu-link" onClick={(e) => {
        e.preventDefault();
        document.getElementById('layout').classList.toggle('active')
        document.getElementById('menu').classList.toggle('active')
        document.getElementById('menuLink').classList.toggle('active')
      }}>
        <span></span>
      </a>
      <Navigation />
      <div id="main">
        <IndexingStatus></IndexingStatus>

        <StaticContainer key={Date.now()}>
          {props.children}
        </StaticContainer>
      </div>
      <Popups {...props} />
    </div>
  );
}
