import React from "react";
import { connect } from 'react-redux';
import Header from '../components/headers/header';
import AddSections from '../components/add/add-sections';
import SearchBox from '../components/search/search';
import { retrieveData } from '../actions/fetch-data';
import { STATS, SEND_CONFIRMATIONS } from '../constants/endpoints';
import { GET_STATS, SEND_CONFIRMATION_REMINDER } from '../constants/constants';

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.retrieveData(STATS, GET_STATS);
  }

  renderStats() {
    const {
      total_users,
      paid_users,
      free_users,
      checklists,
      confirmed_users,
      unconfirmed_users,
      topics,
      chapters,
      parts
    } = this.props.state.stats;

    return (
      <ul className="list-group">
        <li className="list-group-item">
          <span className="badge">{total_users}</span>
          Total Users
        </li>
        <li className="list-group-item">
          <span className="badge">{confirmed_users}</span>
          Confirmed users
        </li>
        <li className="list-group-item">
          <span className="badge">{unconfirmed_users}</span>
          Unconfirmed users
        </li>
        <li className="list-group-item">
          <span className="badge">{paid_users}</span>
          Paid Users
        </li>
        <li className="list-group-item">
          <span className="badge">{free_users}</span>
          Free users
        </li>
        <li className="list-group-item">
          <span className="badge">{checklists}</span>
          Checklists
        </li>
        <li className="list-group-item">
          <span className="badge">{topics}</span>
          Topics
        </li>
        <li className="list-group-item">
          <span className="badge">{chapters}</span>
          Chapters
        </li>
        <li className="list-group-item">
          <span className="badge">{parts}</span>
          Parts
        </li>
      </ul>
    )
  }

  render(){
    const { unconfirmed_users } = this.props.state.stats;
    const { remindersSent } = this.props.state.users;

    return (
      <div>
        <Header title="Dashboard"/>
        <div className="content">
          <SearchBox />
          <AddSections />
          <div className="pure-g dashboard">

            {(unconfirmed_users && !remindersSent ) ? (
                <div className="pure-u-1 send-reminder">
                  <div className="l-box">
                    <h3>Resend confirmation tokens</h3>
                    <div className="alert alert-warning">
                      <p>There seems to be some users who have not confirmed their accounts. Would you like to send them a reminder?</p>
                      <p><strong>Please only use this feature once every other day as users don't like receiving too many messages.</strong></p>
                      <button
                        className="btn button-primary pure-button"
                        onClick={() => this.props.retrieveData(SEND_CONFIRMATIONS, SEND_CONFIRMATION_REMINDER)}
                      >Send reminders</button>
                    </div>
                  </div>
                </div>
              ) : null
             }
             {
               (remindersSent) ? (
                 <div className="pure-u-1 send-reminder">
                   <div className="l-box">
                     <div className="alert alert-success">
                      <p><strong>All the unconfirmed users have been send a reminder to confirm their emails.</strong></p>
                      <p><strong>Please only use this feature once every other day as users don't like receiving too many messages.</strong></p>
                     </div>
                   </div>
                 </div>
               ) : null
             }

            <div className="pure-u-1 pure-u-md-1-2">
              <div className="l-box">
                <h3>Statistics</h3>
                {this.renderStats()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(
  nextState => ({ state: nextState }),
  { retrieveData }
)(Dashboard)
