import React from 'react';
import { connect } from 'react-redux';
import DefaultDelete from './default-delete';
import { CHAPTERS } from '../../constants/endpoints';
import { DELETE_CHAPTER } from '../../constants/constants';

function DeleteChapter({ _id, title, state, dispatch }){
  const chapter_id = _id.$oid;
  const deleteConfig = (title) => ({
    chapter: {
      id: chapter_id
    }
  });

  const noticeMessage = `By deleting the Chapter (${title}) you will be deleting all children that belong to this chapter. This includes all Topic and Checklists belonging to it.`

  const data = {
    sectionTitle: 'Chapter',
    endpoint: `${CHAPTERS}/${chapter_id}`,
    action: DELETE_CHAPTER,
    asyncAction: state.asyncAction,
    deleteConfig,
    dispatch,
    noticeMessage,
    title
  };

  return (
    <DefaultDelete
      {...data}
    />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(DeleteChapter);
