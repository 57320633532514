import { POPUP_OPEN, POPUP_CLOSE, ASYNC_REQUEST_CLEAR_STATUS } from '../constants/constants';
import store from '../store/store';
export function popupOpen(currentPopup, data={}){
  return {
    type: POPUP_OPEN,
    currentPopup,
    data
  };
}

export function popUpClose(){
  store.dispatch({ type: ASYNC_REQUEST_CLEAR_STATUS });
  return {
    type: POPUP_CLOSE
  };
}
