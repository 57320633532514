import React from 'react';
import classnames from 'classnames';
import ErrorNotice from '../notice/standard-error';
import SuccessNotice from '../notice/standard-success';
import { popUpClose } from '../../actions/pop-ups';
import { updateData } from '../../actions/fetch-data';

import RichTextEditor from 'react-rte';

import {
  SERVER_FAILURE_ERROR,
  SERVER_SUCCESS_UPDATE
} from '../../constants/messages';

class DefaultEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorDescription: RichTextEditor.createValueFromString(props.description || '', 'markdown'),
      ...props
    };
    this.onChange = this.onChange.bind(this);
    this.onVideoChange = this.onVideoChange.bind(this);

  }

  componentDidCatch(e) {
    console.error(e)
  }

  handleSubmit(e) {
    e.preventDefault();
    const number = e.target.querySelector('#number').value;
    const title = e.target.querySelector('#title').value;
    const description = this.state.editorDescription.toString('markdown');
    const video = this.state.video;
    const {section, endpoint, action, putData, dispatch} = this.props;

    dispatch(
      updateData(
        endpoint,
        action,
        putData(title, number, description, video),
        section
      )
    );
  }

  onChange(value) {
    this.setState({editorDescription: value});
  }

  onVideoChange(e) {
    this.setState({video: e.target.value});
  }

  render() {
    const { title, number, sectionTitle, dispatch, asyncAction } = this.props;
    const { editorDescription, video } = this.state;
    const {
      requestActive,
      updateSuccess,
      updateFailed
    } = asyncAction;

    const loadingClasses = classnames({
      'loading--left': true,
      hide: !requestActive
    });

    return (
      <div className="modal">
        <div className="modal-dialog">
          <form onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  aria-hidden="true"
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch( popUpClose() )
                  }}
                >&times;</button>
                <h4 className="modal-title">{`Edit ${sectionTitle}: ${title}`}</h4>
              </div>
              <div className="modal-body">
                <ErrorNotice msg={SERVER_FAILURE_ERROR} shouldDisplay={updateFailed} />
                <SuccessNotice msg={SERVER_SUCCESS_UPDATE} shouldDisplay={updateSuccess} />
                <div className="pure-control-group">
                  <label htmlFor="number">Number:</label>
                  <input
                    id="number"
                    type="text"
                    placeholder="Number"
                    defaultValue={number}
                    className="form-control pure-input-1"
                  />
                </div>
                <div className="pure-control-group">
                  <label htmlFor="title">Title:</label>
                  <input
                    id="title"
                    type="text"
                    placeholder="Please enter a title"
                    defaultValue={title}
                    className="form-control pure-input-1"
                    required="true"
                  />
                </div>

                <div className="pure-control-group">
                  <label htmlFor="description">Description:</label>

                  <div className="force-80-height overflow-y">
                    <RichTextEditor
                      value={editorDescription}
                      placeholder="An accessible description to help guide less qualified or familiar users"
                      onChange={this.onChange}
                      />
                  </div>
                </div>

                <div className="pure-control-group">
                  <label htmlFor="video">Video:</label>
                  <input
                    id="video"
                    type="text"
                    placeholder="YouTube video code e.g. gIACm4ENHaM"
                    defaultValue={video ? video : ''}
                    className="form-control pure-input-1"
                    onChange={this.onVideoChange}
                  />
                </div>

                {video ?
                  <div className="pure-control-group">
                    <label>Preview</label>
                    <iframe style={{
                      width: 540,
                      height: 315
                    }} width="560" height="315" src={`https://www.youtube.com/embed/${video}`} title="Neurochecklists Video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                  : null
                }
              </div>
              <div className="modal-footer">
                <img
                  className={loadingClasses}
                  src={require('../../../images/loading-c.gif')}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch( popUpClose() )
                  }}
                  className="btn button-cancel pure-button"
                >Cancel</button>
                <button
                  type="submit"
                  className="btn button-primary pure-button"
                >Save changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

}

export default DefaultEdit;
