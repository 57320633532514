import React from "react";
import StaticContainer from 'react-static-container';


export default (props) => (
  <div className='main-container'>
    <StaticContainer key={Date.now()}>
      {props.children}
    </StaticContainer>
  </div>
);
