import React from "react";

export default ({hasError}) => {
  if(!Boolean(hasError.error))
    return <div />;

  return (
    <div className="notice notice__error">
      <span>{hasError.errMsg.error}</span>
    </div>
  );
}
