import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  ADD_PART_POPUP,
  EDIT_PART_POPUP,
  DELETE_PART_POPUP,
  ADD_CHAPTER_POPUP,
  EDIT_CHAPTER_POPUP,
  DELETE_CHAPTER_POPUP,
  ADD_TOPIC_POPUP,
  EDIT_TOPIC_POPUP,
  DELETE_TOPIC_POPUP,
  ADD_CHECKLIST_POPUP,
  EDIT_CHECKLIST_POPUP,
  DELETE_CHECKLIST_POPUP,
  VIEW_USER_POPUP,
  DELETE_USER_POPUP
} from '../../constants/constants';
import PopupContainer from './popup-container';
import AddPart from './add-part';
import AddChapter from './add-chapter';
import AddTopic from './add-topic';
import AddChecklist from './add-checklist';
import EditPart from './edit-part';
import EditChapter from './edit-chapter';
import EditTopic from './edit-topic';
import EditChecklist from './edit-checklist';
import DeletePart from './delete-part';
import DeleteChapter from './delete-chapter';
import DeleteTopic from './delete-topic';
import DeleteChecklist from './delete-checklist';
import ViewUser from './view-user';
import DeleteUser from './delete-user';

class Popups extends React.Component {
  displayPopup(name, popupState){
    if(name === popupState.currentPopup && popupState.popupIsOpened){
      return true;
    } else {
      return false;
    }
  }

  render(){
    const { popups } = this.props.state;

    return (
      <div>
        {
          (this.displayPopup(ADD_PART_POPUP, popups)) ? (
            <PopupContainer {...this.props}>
              <AddPart />
            </PopupContainer>
          ) : null
        }
        {
          (this.displayPopup(EDIT_PART_POPUP, popups)) ? (
            <PopupContainer {...this.props}>
              <EditPart {...popups.data} />
            </PopupContainer>
          ) : null
        }
        {
          (this.displayPopup(DELETE_PART_POPUP, popups)) ? (
            <PopupContainer {...this.props}>
              <DeletePart {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          (this.displayPopup(ADD_CHAPTER_POPUP, popups)) ? (
            <PopupContainer {...this.props}>
              <AddChapter />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(EDIT_CHAPTER_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <EditChapter {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(DELETE_CHAPTER_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <DeleteChapter {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(ADD_TOPIC_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <AddTopic {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(EDIT_TOPIC_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <EditTopic {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(DELETE_TOPIC_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <DeleteTopic {...popups.data}/>
            </PopupContainer>
          ) : null
        }


        {
          this.displayPopup(ADD_CHECKLIST_POPUP, popups) ? (
            <PopupContainer large={true} {...this.props}>
              <AddChecklist />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(EDIT_CHECKLIST_POPUP, popups) ? (
            <PopupContainer large={true} {...this.props}>
              <EditChecklist {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(DELETE_CHECKLIST_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <DeleteChecklist {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(VIEW_USER_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <ViewUser {...popups.data} />
            </PopupContainer>
          ) : null
        }

        {
          this.displayPopup(DELETE_USER_POPUP, popups) ? (
            <PopupContainer {...this.props}>
              <DeleteUser {...popups.data} />
            </PopupContainer>
          ) : null
        }
      </div>
    );
  }

}

export default connect(
  nextState => ({state: nextState})
)(Popups);
