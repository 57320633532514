import React from 'react';
import { connect } from 'react-redux';
import { PARTS } from '../../constants/endpoints';
import { ADD_PART } from '../../constants/constants';
import DefaultAdd from './default-add';

function AddPart({ dispatch, state }){
  const { asyncAction } = state;
  const postData = (title, number, description, video) => ({
    part: {
      title,
      number,
      description,
      video,
    }
  });

  const data = {
    sectionTitle: 'Part',
    endpoint: `${PARTS}`,
    action: ADD_PART,
    postData,
    dispatch,
    asyncAction
  };

  return (
    <DefaultAdd {...data} />
  )
}

export default connect(
  nextState => ({ state: nextState })
)(AddPart);
