import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

import queryString from 'query-string';
import {browserHistory} from 'react-router';

export default class SortButtons extends React.Component {
    constructor() {
        super();
        this.updateSearch = this.updateSearch.bind(this);
    }
    updateSearch() {
        let qs = queryString.parse(window.location.search);

        if (qs.order === this.props.searchKey) {
            qs.direction = (qs.direction && qs.direction === 'asc') ? 'desc' : 'asc';
        } else {
            qs.order = this.props.searchKey;
            qs.direction = 'asc';
        }

        let search = '?' + queryString.stringify(qs);

        browserHistory.replace(window.location.pathname + search);
    }
    render() {
        return (
        <span>
            <FontAwesomeIcon icon={faSort} onClick={this.updateSearch} />
        </span>
        );
    }
}
