import {
  FETCH_PROMO,
  CREATE_PROMO
} from '../constants/constants';

const defaultState = {
  list: []
};

export default function configReducer(state=defaultState, action){
  switch (action.type) {
    case FETCH_PROMO:
      return Object.assign({}, state, {list: action.data.promos})
    case CREATE_PROMO:
      state.list.unshift(action.data);
      return Object.assign({}, state);
    default:
      return state;
  }
}
