import {
  FETCH_PARTS_DATA,
  PART_SELECTED,
  ADD_PART,
  UPDATE_PART,
  DELETE_PART,
  FETCH_PART_CHAPTER_DATA,
  ADD_CHAPTER,
  UPDATE_CHAPTER,
  DELETE_CHAPTER
} from '../constants/constants';

const defaultState = {
  parts: []
};

export default function PartsReducer(state=defaultState, action){
  switch (action.type) {
    case PART_SELECTED:
      const parts = state.parts.map( part => {
        if(part._id.$oid === action.id) part.selected = !part.selected;
        return part;
      });

      return Object.assign({}, state, {
        parts
      });

    case FETCH_PARTS_DATA:
      const data = action.data.map((item)=>{
        return Object.assign({}, item, {
          selected: false,
          chapters: []
        });
      });

      return Object.assign({}, state, {
        parts: data
      });

    case ADD_PART:
      const addedPart = state.parts.concat(action.data);

      return Object.assign({}, state, {
        parts: addedPart
      });

    case UPDATE_PART:
      const updatedPart = state.parts.map( part => {
        if(part._id.$oid === action.data._id.$oid) {
          part.title = action.data.title;
        }
        return part;
      });

      return Object.assign({}, state, {
        parts: updatedPart
      });

    case DELETE_PART:
      const partWithoutdeletedPart = state.parts.filter(
        part => part._id.$oid !== action.data._id.$oid
      );

      return Object.assign({}, state, {
        parts: partWithoutdeletedPart
      });


    case FETCH_PART_CHAPTER_DATA:
      const fetchedPart = state.parts.map( part => {
        if(part._id.$oid === action.id)
          part.chapters = action.data;

        return part;
      });

      return Object.assign({}, state, {
        parts: fetchedPart
      });

    case ADD_CHAPTER:
      const addedPartChapter = state.parts.map( part => {
        if(part._id.$oid === action.data.part_id.$oid){
          if(part.chapters)
            part.chapters = part.chapters.concat(action.data);
          else
            part.chapters = [].concat(action.data);
        }
        return part;
      });

      return Object.assign({}, state, {
        parts: addedPartChapter
      });

    case UPDATE_CHAPTER:
      const updatedPartChapter = state.parts.map( part => {
        if(part._id.$oid === action.data.part_id.$oid) {
          part.chapters = part.chapters.map( (chapter) => {
            if(chapter._id.$oid == action.data._id.$oid) {
              chapter.title = action.data.title;
              chapter.part_id = action.data.part_id;
            }
            return chapter;
          });
        }
        return part;
      });

      return Object.assign({}, state, {
        parts: updatedPartChapter
      });

    case DELETE_CHAPTER:
      const deletePartChapter = state.parts.map( (part) => {
        if(part._id.$oid === action.data.part_id.$oid) {
          part.chapters = part.chapters.filter(
            chapter => chapter._id.$oid !== action.data._id.$oid
          );
        }
        return part;
      });

      return Object.assign({}, state, {
        parts: deletePartChapter
      });

    default:
      return state;
  }
}
