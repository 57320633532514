import React from "react";
import { connect } from "react-redux";
import { retrieveData } from "../../actions/fetch-data";

import SuccessNotice from "../notice/standard-success";
import WarningNotice from "../notice/standard-warning";

import { INDEXING_STATUS } from "../../constants/endpoints";
import { FETCH_INDEXING_STATUS } from "../../constants/constants";

import Store from "../../store/store";

import "./indexing-status.css";

const TEN_SECONDS = 10000;

class IndexingStatus extends React.Component {
  constructor() {
    super();
    this.state = {
      in_queue: false,
      lastUpdated: false
    };
    this.onChange = this.onChange.bind(this);
    this.unsubscribe = Store.subscribe(this.onChange);
  }
  componentDidMount() {
    this.props.retrieveData(INDEXING_STATUS, FETCH_INDEXING_STATUS);
    this.timer = setInterval(() => {
      this.props.retrieveData(INDEXING_STATUS, FETCH_INDEXING_STATUS);
    }, TEN_SECONDS);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.unsubscribe();
  }

  onChange() {
    let newState = Store.getState().indexing;
    this.setState({
      lastUpdated: new Date().toLocaleString(),
      ...newState
    });
  }

  render() {
    if (this.state.in_queue === false) {
      return null;
    }
    let message =
      this.state.in_queue > 0
        ? "Index is updating (" + this.state.in_queue + " jobs pending)"
        : "Index up to date";
    return (
      <div className="indexing-block">
        {this.state.in_queue > 0 ? (
          <WarningNotice msg={message} shouldDisplay={true} />
        ) : (
          <SuccessNotice msg={message} shouldDisplay={true} />
        )}
        {this.state.lastUpdated ? (
          <small className="indexing-info">
            Last checked {this.state.lastUpdated}
          </small>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(
  nextState => ({ state: nextState }),
  { retrieveData }
)(IndexingStatus);
