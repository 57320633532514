import 'babel-polyfill';

import React from 'react';
import { render } from 'react-dom';
import store from './store/store';
import routes from './routes';
import '../css/main.css';

import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

@DragDropContext(HTML5Backend)
class App extends React.Component {
    render() {
        return routes(store);
    }
}

render(React.createElement(App), document.getElementById("app"));
